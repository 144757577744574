import React, {Fragment, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {ErrorMessage, Form, useAPI} from 'components/lib';
import axios from "axios";

export function ChipModal(props) {

  const transponderModels = useAPI('/api/transponderModels/');
  const cancelButtonRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const chipData = props.context.data;

  async function addChip(newChip) {

    // Validate new record
    if (!newChip) {
      return ['Chip data was empty'];
    }
    const errors = [];
    if (!newChip.transponder_model_id) {
      errors.push('Chip Model was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    if (props.enableAutoSave) {
      // send create request to server

      const response = await axios({

        method: 'post',
        url: '/api/transponderConfig/',
        data: {data: newChip},

      });

      console.log(response);
      if (response.status === 200) {
        // Update state
        props.addChip(response.data.data);
      }
      else if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.statusText,
          message: 'Error when creating chip.'
        }]
      }
    }
    else {
      // add temp id (will be overwritten by server)
      newChip.id = Date.now();
      props.addChip(newChip);
    }
    return null; // no errors
  }

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="">
                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                      Add Chip
                    </Dialog.Title>
                    {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}
                    <div className="mt-2">
                      <Form
                          updateOnChange={true}
                          onChange={(e) => {
                            chipData[e.input] = e.value;
                          }}
                          className={"p-1 bg-white space-y-5 sm:rounded-lg"}
                          flex={true}
                          data={{
                            transponder_model_id: {
                              label: 'Chip Model',
                              type: 'select',
                              options: (!transponderModels.loading && transponderModels.data) ? transponderModels.data.map(x => {return {value: x.id, label: x.name}}) : [],
                              required: true,
                              value: chipData.transponder_model_id || undefined,
                              default: chipData.transponder_model_id || undefined,
                              errorMessage: 'Please select a chip model',
                            },
                            instructions: {
                              label: 'Special Instructions',
                              type: 'textarea',
                              required: false,
                              value: chipData.instructions || undefined,
                              errorMessage: 'Please enter instructions',
                            },
                            collect_chip_uid: {
                              label: 'Collect UID During Production',
                              type: 'singleCheckbox',
                              required: false,
                              value: chipData.collect_chip_uid,
                              errorMessage: 'Please select an option',
                            },
                            lock_all: {
                              label: 'Lock All Records',
                              type: 'singleCheckbox',
                              required: false,
                              value: chipData.lock_all,
                              errorMessage: 'Please select an option',
                            },
                          }}>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={async () => {
                        const e = await addChip(chipData);
                        if (!e) {
                          props.setOpen(false);
                          setErrors([]);
                        }
                        else {
                          setErrors(e);
                        }
                      }}
                  >
                    Create Chip
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                        setErrors([]);
                      }}
                      ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
