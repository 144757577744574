/***
 *
 *   CAIDS
 *   View CAIDs
 *
 **********/

import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {
  AppLayoutContext, AuthContext, Button, Form,
  Loader, TextInput, ViewContext,
  useAPI, SingleCheckbox
} from 'components/lib';
import Style from "../../components/hero/hero.tailwind";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function extractErrorText(response) {
  let errorText = undefined;
  let errorObj = undefined;
  if (response.data.error) {
    errorObj = response.data.error;
  }
  else if (response.data.data.error) {
    errorObj = response.data.data.error;
  }
  else if (response.data.detail) {
    errorText = response.data.detail;
  }
  if (errorObj) {
    if (errorObj.message) {
      errorText = errorObj.message;
    }
    else if (errorObj.error) {
      errorText = errorObj.error;
    }
    else if (errorObj.detail) {
      errorText = errorObj.detail;
    }
    else if (typeof errorObj === 'string') {
      errorText = errorObj;
    }
  }
  return errorText;
}

export function ConfigureCaidGeneration(props){
  const context = useContext(AuthContext);
  const contextNew = useContext(ViewContext);
  const { orderId, orderItemId } = useParams();
  let query = useQuery();
  const customerCaids = useAPI('/api/orders/' + orderId + '/caidScope');
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');
  const orderFromApi = useAPI('/api/orders/' + orderId);

  let navigate = useNavigate();
  const [orderCount, setOrderCount] = useState(query.get("count"));
  const [extraPercent, setExtraPercent] = useState(query.get("extra"));
  const [selectedCaidScopes, setSelectedCaidScopes] = useState(undefined);
  const [generationPendingMessage, setGenerationPendingMessage] = useState(undefined);
  const [generationErrors, setGenerationErrors] = useState([]);


  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  // TODO: this should handle multiple caidScopes
  async function generate(caidScopes) {

    // Validate
    const errors = [];
    if (!orderCount) {
      errors.push('Order Count was missing');
    }
    if (!extraPercent) {
      errors.push('Extra Percent was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    let completedCount = 0;
    setGenerationPendingMessage(`Generation in progress. ${completedCount} of ${caidScopes.length} CAID Scopes complete.`);
    // TODO: long term, this logic should be managed in the server (more robust, SQL transactions, etc)
    const generationConfigId = uuidv4();
    let res=0;
    let error=false;
    for(let i = 0; i < caidScopes.length; i++) {
      let caidScope = caidScopes[i];

      try {
        // send create request to server
        const response = await axios({

          method: 'post',
          url: `/api/caidScope/${caidScope.id}/generate`,
          data: {
            orderCount: orderCount,
            extraPercent: extraPercent,
            userId: context.user?.user_id,
            orderItemId: orderItemId,
            generationConfigId: generationConfigId,
          },

        });

        console.log(response);
        res=response;
        error=response.data.error;
        if (response.status === 200 && response.data.success) {
          completedCount++;
          setGenerationPendingMessage(`Generation in progress. ${completedCount} of ${caidScopes.length} CAID Scopes complete.`);
        } else {
          setGenerationPendingMessage(`Generation failed. ${completedCount} of ${caidScopes.length} CAID Scopes complete.`);
          // return error if not '200 OK'
          const errorText = extractErrorText(response);
          return [{
            additionalErrorText: errorText,
            message: 'Error when generating CAIDs.'
          }]
        }
      } catch (e) {
        let errorText = extractErrorText(e.response || e.data);
        return [{
          additionalErrorText: errorText,
          message: 'Error when generating CAIDs.'
        }]
      }

    }

    if(error){
      setGenerationPendingMessage(`IDs Generation is failed!`);
      contextNew.notification.show(res.data.message, 'error', false);
    }else{
      setGenerationPendingMessage(`Generation is Completed!`);
      contextNew.notification.show(res.data.message, 'success', false);
    }

    // window.location.reload();
    return;
  }

  return (
      <Fragment>

        { users.loading || customers.loading || customerCaids.loading || !orderFromApi.data ?
            <Loader /> :
            <>
              <div className={"flex mb-4 -mt-2"}>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/orders`}>
                  Orders
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/order/${orderId}`}>
                  {orderFromApi.data.vtiger_salesorder_no}
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <p className={"relative text-sm text-gray-500"}>
                  CAID Generation Config
                </p>
              </div>
              <div className={"space-y-2 shadow bg-gray-50 rounded-lg p-3"}>
                <div className={"-mb-4"}>
                  <Form
                      updateOnChange={true}
                      onChange={(e) => {
                        setSelectedCaidScopes(e.value);
                      }}
                      className={"bg-gray-50 p-2 rounded-sm w-72 inline-block"}
                      flex={false}
                      data={{
                        caidScopes: {
                          label: 'CAID Scopes',
                          type: 'customMultiSelect',
                          options: customerCaids.data ? customerCaids.data.map(x => {return {value: x.id, label: `${x.name}`, secondaryText: x.sample_value, orig: x}}) : [],
                          value: [],
                          required: true,
                          // default:  dataRecord.data_sources ? dataRecord.data_sources : [],
                          errorMessage: 'Please select CAIDs',
                        },
                      }}>
                  </Form>
                  <a href={`/customer/${orderFromApi.data.customer_id}/caid`} className="hover:underline text-sm text-orange-500 ml-2">
                    Manage CAID Scopes
                  </a>

                </div>
                <div className={"p-2"}>
                  <label className="block text-sm font-medium text-gray-700 uppercase" id="headlessui-listbox-label-5">
                    COUNT
                  </label>
                  <TextInput placeholder={"Number to Gen."} value={orderCount} onChange={(name, value) => setOrderCount(value)} outerClassName={"mr-2 mt-2"} />

                  <label className="mt-4 block text-sm font-medium text-gray-700 uppercase" id="headlessui-listbox-label-5">
                    EXTRA PERCENT (%)
                  </label>
                  <TextInput placeholder={"Extra %"} value={extraPercent} onChange={(name, value) => setExtraPercent(value)} outerClassName={"mr-2 mt-2"} />
                </div>
                <div className={"flex"}>
                  <Button
                      disabled={!(orderCount && orderCount !== 0 && extraPercent && selectedCaidScopes && selectedCaidScopes.length > 0 && !generationPendingMessage)}
                      action={async () => {
                        const errors = await generate(selectedCaidScopes);
                        if (errors) {
                          setGenerationErrors(errors);
                        }
                      }}
                      text={generationPendingMessage ? generationPendingMessage : 'Generate'}
                      small
                      className={`mt-2 mr-4 border border-transparent 
              ${(orderCount && orderCount !== 0 && extraPercent && selectedCaidScopes && selectedCaidScopes.length > 0 && !generationPendingMessage) ?
                          "bg-orange-500 hover:bg-orange-700 w-96" :
                          "bg-gray-400 cursor-not-allowed w-96"}`}/>

                  <Button
                      action={async () => {
                        navigate(`/order/${orderId}`);
                      }}
                      text={'Return to Order'}
                      small
                      className={`mt-2 mr-2 w-72 
                ${!generationPendingMessage ? 'border text-gray-700 bg-indigo-700 hover:bg-indigo-700' : 'border border-transparent bg-orange-500 hover:bg-orange-700'}`}/>
                </div>
                {(generationErrors && generationErrors.length > 0) && <div className={"flex text-red-500"}>
                  {
                    generationErrors.map((error, index) => {
                      return (
                          <div key={'generationError_' + index}>
                            {error.message ? error.message : error}
                            <br />
                            {error.additionalErrorText}
                          </div>
                      );
                    })
                  }
                </div>}

              </div>
            </>
        }
      </Fragment>
  )
}
