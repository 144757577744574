/***
 *
 *   CAIDS
 *   View CAIDs
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  AppLayoutContext, Button,
  DataSpecNumberCell,
  DateCell,
  Loader,
  ReactTable,
  useAPI
} from 'components/lib';
import Style from "../../../components/hero/hero.tailwind";
import {CreateCaidScopeModal} from "./CreateCaidScopeModal";
import {UploadOffensiveWords} from "./UploadOffensiveWords";
import axios from "axios";
import {ImportBundleIDModal} from "../../dataspec/shared/ImportBundleIDModal";

export function Caids(props){

  const { customerId } = useParams();

  function CaidScopeCell({ row, value }) {
    return (
      <a href={`/customer/${customerId}/caid/${row.original.id}`} className="hover:underline text-sm text-orange-500">
        {value}
      </a>
    );
  }

  const customerCaids = useAPI('/api/customer/' + customerId + '/caid');
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');
  const caidTypes = useAPI('/api/caidTypes/');
  console.log(customerCaids);

  let navigate = useNavigate();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUploadWordsModal, setOpenUploadWordsModal] = useState(false);
  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: 'name',
      Cell: CaidScopeCell,
    },
    {
      Header: "Description",
      accessor: (row) => {
        return row.description && row.description.length > 130 ? row.description.slice(0,130) + '...': row.description;
      },
    },
    {
      Header: "Type",
      accessor: (row) => {
        return row && row.caidFormat.caid_type_id && caidTypes.data ? caidTypes.data.find(u => u.id === row.caidFormat.caid_type_id).name: row.caidFormat.caid_type_id
      },
    },
    {
      Header: "Min. Length",
      accessor: (row) => {
        return row.caidFormat.min_length;
      },
    },
    {
      Header: "Max. Length",
      accessor: (row) => {
        return row.caidFormat.max_length;
      },
    },
    {
      Header: "Leading Zeros",
      accessor: (row) => {
        return row.caidFormat.allow_leading_zeros;
      },
    },
    {
      Header: "Starting Value",
      accessor: (row) => {
        return row.caidFormat.starting_value;
      },
    },
    {
      Header: "Prefix",
      accessor: (row) => {
        return row.caidFormat.prefix;
      },
    },
    {
      Header: "Suffix",
      accessor: (row) => {
        return row.caidFormat.suffix;
      },
    },
    {
      Header: "Created By",
      accessor: (row) => {
        return row && row.created_by_id && users.data ? users.data.find(u => u.id === row.created_by_id).name: row.created_by_id
      },
    },
  ], [users, customers])

  return (
    <Fragment>

      { customerCaids.data == null || customerCaids.loading || users.loading || customers.loading || caidTypes.loading ?
        <Loader /> :
        <>
          <div className={"flex mb-4 -mt-2"}>
            <a className={"relative text-sm hover:underline text-orange-500"}
               href={`/customers`}>
              Customers
            </a>
            <span className={"mx-2 text-sm text-gray-400"}> / </span>
            <a className={"relative text-sm hover:underline text-orange-500"}
               href={`/customer/${customerId}`}>
              {customers.data ? customers.data.find(u => u.id === customerId).name: customerId}
            </a>
            <span className={"mx-2 text-sm text-gray-400"}> / </span>
            <p className={"relative text-sm text-gray-500"}>
              Manage CAID Scopes
            </p>
          </div>
          {/*<Button goto='/dataspec/new' text='New Data Spec' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>*/}
          <div className={"flex"}>
            <Button action={() => {
              setOpenCreateModal(true);
            }} text='Create CAID' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>
            <Button action={() => {
              setOpenUploadWordsModal(true);
            }} text='Import Offensive Words' small className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>
          </div>

          <ReactTable columns={columns} data={customerCaids.data} />
          <CreateCaidScopeModal open={openCreateModal}
                               setOpen={setOpenCreateModal}
                                customerId={customerId}
                               afterCreate={(data) => {
                                 window.location.reload();
                               }}
          />

          <UploadOffensiveWords open={openUploadWordsModal}
                                setOpen={setOpenUploadWordsModal}
                                customerId={customerId}
                                afterCreate={(data) => {
                                  window.location.reload();
                                }}
          />

        </>
      }
    </Fragment>
  )
}
