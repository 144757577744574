
export function DataSpecNumberCell({ row, value }) {
  // console.log(row)

  return (
    <a href={`/dataSpec/${row.original.id}`} className="hover:underline text-sm text-orange-500">
      {value}
    </a>
  );
}

export function OrderNumberCell({ row, value }) {
  // console.log(row)

  return (
    <a href={`/order/${row.original.id}`} className="hover:underline text-sm text-orange-500">
      {value}
    </a>
  );
}

export function CustomerCell({ row, value }) {
  // console.log(row)

  return (
    <a href={`/customer/${row.original.id}`} className="hover:underline text-sm text-orange-500">
      {value}
    </a>
  );
}
