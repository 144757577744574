/***
 *
 *   VIEW ORDER
 *   View a sales order
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {AppLayoutContext, Button, Form, Icon, Loader, ReactTable, useAPI} from 'components/lib';
import axios from "axios";
import {CreateDataSpecModal} from "../dataspec/shared/CreateDataSpecModal";
import {ImportFactoryModal} from "../dataspec/shared/ImportFactoryModal";
import {ExportClientDataModal} from "../dataspec/shared/ExportClientDataModal"
import Style from "../../components/form/checkbox/checkbox.tailwind";
import {ImportBundleIDModal} from "../dataspec/shared/ImportBundleIDModal";
import fileDownload from "js-file-download";

function DisplayProperties(props) {

  return (<div>
    {
      Object.keys(props.data).map(function(key) {
        return <div className="text-xs" key={key}>
          <strong>{key} : </strong>
          { props.data[key] }
        </div>
      })
    }
  </div>);
}

export function ViewOrder(props){

  let navigate = useNavigate();
  const { orderId } = useParams();
  const customers = useAPI('/api/customer/');
  const orderFromApi = useAPI('/api/orders/' + orderId);
  // const syncResponse = useAPI('/api/orders/sync/' + orderId);
  const orderItemsFromApi = useAPI('/api/orderItem/' + orderId);
  const [orderData, setOrderData] = useState(orderFromApi.data);
  const [orderItemData, setOrderItemData] = useState(orderItemsFromApi.data);
  const [openDataSpecModal, setOpenDataSpecModal] = useState(false);
  const [openImportFactoryModal, setOpenImportFactoryModal] = useState(false);
  const [openExportClientDataModal, setOpenExportClientDataModal] = useState(false);
  const [exportFileName, setExportFileName] = useState('');

  const [openImportBundleIDModal, setOpenImportBundleIDModal] = useState(false);
  const [orderItemId , setOrderItemId] = useState(false);
  const [customerOrdersId , setCustomerOrdersId] = useState(false);
  const [isStandalone , setIsStandalone] = useState(false);

  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);
  useEffect( () => {
    async function asyncFunction() {
      if (orderFromApi.data) {
        let now = new Date();
        let syncDate = new Date(orderFromApi.data.updated_at);
        let diffMins = (now - syncDate) / 60000; // minutes
        if (diffMins > 15) {
          const response = await axios({

            method: 'get',
            url: '/api/orders/sync/' + orderId,

          });
          setOrderData(response.data.data);


          const itemsResponse = await axios({

            method: 'get',
            url: '/api/orderItem/' + orderId,

          });
          setOrderItemData(itemsResponse.data.data);
        } else {
          setOrderData(orderFromApi.data);
        }

      }
    }
    asyncFunction();
  }, [orderFromApi]);
  useEffect( () => {
    async function asyncFunction() {
      if (orderItemsFromApi.data) {
        setOrderItemData(orderItemsFromApi.data);
      }
    }
    asyncFunction();
  }, [orderItemsFromApi]);

  function ItemNameCell({row, value}) {
    return (
        <div className={"flex flex-col"}>
          <div className="text-md font-bold text-gray-700">
            {row.original.product_name}
          </div>
          <div className={"text-sm text-gray-500 whitespace-pre-line"}>
            {row.original.details}
          </div>
        </div>);
  }

  function ItemQuantityCell({row, value}) {
    return (
        <div className={"flex flex-col"}>
          <div className="text-md text-center">
            {row.original.quantity}
          </div>

          <Button
              action={async () => {
                setOrderItemId(row.original.id);
                if(row.original.data_spec_id===null){

                }
                // console.log("RoewData:"+JSON.stringify(row.original));
                setExportFileName(`${orderFromApi.data.vtiger_salesorder_no}_LineItem${row.original.number}`)
                setOpenExportClientDataModal(true);
              }}
              icon={'upload'}
              text='Export Data for Client' xSmall
              className='mt-2 bg-orange-500 hover:bg-orange-700'/>


        </div>);
  }

  function DataSpecCell({ row, value }) {
    // console.log(row)

    return (<>
      {!row.original.data_spec_id ?
          <Button
              icon={'link'}
              action={async () => {
                setOrderItemId(row.original.id);
                setOpenDataSpecModal(true);
              }} text='Link Data Spec' xSmall className={"bg-orange-500 hover:bg-orange-700"}/> :
          <div className={"flex gap-6 items-center"}>
            <a href={`/dataspec/${row.original.data_spec_id}`} className="hover:underline text-sm text-orange-500">
              {row.original.data_spec_number}
            </a>
            <span className={"cursor-pointer"} onClick={async () => {
              // Remove order item link to data spec
              const response = await axios({

                method: 'post',
                url: '/api/orderItem/unlink/' + row.original.id,
                data: {},

              });

              // Refresh data for order item
              const itemsResponse = await axios({

                method: 'get',
                url: '/api/orderItem/' + row.original.order_id,

              });
              setOrderItemData(itemsResponse.data.data);
            }
            }>
            <Icon
                className='icon'
                image={'trash'}
                size={20}
                color={'red'}
            />
          </span>
          </div>
      }
    </>);

  }
  function IdGenCell({ row, value }) {
    console.log(row)
    setCustomerOrdersId(row.original.order_id);
    if (row.original.data_list_source_id===1 && !row.original.generation_config_id){
      return (
          <>
            <span className={'text-sm text-gray-500 whitespace-pre-line'}>Customer Provided</span>
          </>
      );
    }
    else{
      return (<>

        { !row.original.generation_config_id  ?
            <Button
                action={async () => {
                  navigate(`/order/${orderId}/generation/${row.original.id}?count=${row.original.quantity}&extra=${15}`);
                }}
                icon='settings'
                text='Configure ID Gen' xSmall className={ "bg-orange-500 hover:bg-orange-700"}/> :
            <div className={"flex gap-4 items-center"}>
              <div>
                {row.original.idgencounts.map((idgc) => {
                  return (

                      <div className={"flex flex-col mb-2"} key={`${idgc.caid_scope_id}_${idgc.caid_batch_id}`}>
                        <a
                            href={`/customer/${orderFromApi.data.customer_id}/caid/${idgc.caid_scope_id}?batchId=${idgc.caid_batch_id}`}
                            className="hover:underline text-sm text-orange-500">
                          {(parseInt(idgc.count)/row.original.idgencounts.length).toLocaleString()} CAIDs generated
                        </a>
                        <div className={"text-sm text-gray-400 -mt-1"}>
                          {idgc.caid_scope_name}
                        </div>
                      </div>);

                })
                }
              </div>
              <span className={"cursor-pointer"} onClick={async () => {
                // delete generation batches and generation config
                const response = await axios({

                  method: 'post',
                  url: '/api/orderItem/deleteGeneration/' + row.original.id,
                  data: {},

                });
                window.location.reload();
              }
              }>
                <Icon
                    className='icon'
                    image={'trash'}
                    size={20}
                    color={'red'}
                />
              </span>
            </div>
        }
      </>);
    }
  }
  function FactoryExportCell({ row }) {
    const [isChecked, setIsChecked] = useState(false)

    const checkHandler = () => {
      setIsChecked(!isChecked)
    }
    return (<>
      <div className={'factory_operations'}>
        <div>
          <Button
              action={async () => {
                navigate(`/order/${orderId}/factoryExport/${row.original.id}?dataSpecId=${row.original.data_spec_id}${row.original.generation_config_id ? '&generationConfigId=' + row.original.generation_config_id: ''}`);
              }}
              disabled={!row.original.data_spec_id}
              icon={'upload'}
              text='Export Data for Production' xSmall
              className={ row.original.data_spec_id ? "bg-orange-500 hover:bg-orange-700" :
                  "bg-gray-300 cursor-not-allowed"}/>


          {row.original.standalone_batchID===null ?
              <>
              <Button
                  action={async () => {
                    setOrderItemId(row.original.id);
                    console.log("Roww Data:"+JSON.stringify(row.original));
                    if(row.original.data_spec_id===null || row.original.generation_config_id===null){
                      setIsStandalone(true);
                    }else{
                        setIsStandalone(false);
                    }
                    setOpenImportFactoryModal(true);
                  }}
                  icon={'download'}
                  text='Import Realized Data' xSmall
                  className='mt-2 bg-orange-500 hover:bg-orange-700'/>

              <Button
                    action={async () => {
                      setOrderItemId(row.original.id);
                      setOpenImportBundleIDModal(true);
                    }}
                    icon={'download'}
                    text='Import Bundle IDs' xSmall
                    className='mt-2 bg-orange-500 hover:bg-orange-700'/>
              </>

              : <Button
                  action={async () => {
                     // navigate('/customer/'+orderFromApi.data.customer_id+'/caid/b9949c1c-c62f-4699-8913-64bc926bf117')
                     window.open('/customer/'+orderFromApi.data.customer_id+'/caid/b9949c1c-c62f-4699-8913-64bc926bf117','_blank')
                  }}
                  icon={'feather'}
                  text='Check Linked UID' xSmall
                  className='mt-2 bg-orange-500 hover:bg-orange-700'/>
          }


        </div>
      </div>

    </>);
  }

  function CollectChipUID({ row }) {
    let isCheckedInitial= false;
    if(row.original.collect_chip_uid || row.original.collect_uid){
      isCheckedInitial=true
    }
    const [isChecked, setIsChecked] = useState(isCheckedInitial)
    let isDisabled=false;
    if (row.original.collect_uid===true){
      isDisabled=true;
    }

    const checkHandler = async () => {
      setIsChecked(!isChecked)
      const response = await axios({
        method: 'post',
        url: '/api/orderItem/collectChipUid/' + row.original.id,
        data: {val: !isChecked},

      });

      // window.location.reload();

    }


    return (<>
      <div className={'factory_operations'}>
        <div className={'collect-chip-uid'}>
          <input
              type="checkbox"
              id="collect-chip-uid"
              className={ Style.input }
              checked={isChecked}
              disabled= {isDisabled}
              onChange={checkHandler}
          />
        </div>
      </div>

    </>);
  }

  const columns = React.useMemo(() => [
    {
      Header: "Item Number",
      accessor: 'number',
    },
    {
      Header: "Data Spec",
      accessor: 'id',
      Cell: DataSpecCell,
    },
    {
      Header: "CAID Generation",
      accessor: 'generation_config_id',
      Cell: IdGenCell,
    },
    {
      Header: "Factory Data",
      Cell: FactoryExportCell,
    },
    {
      Header: "Collect UID",
      Cell: CollectChipUID,
    },
    {
      Header: "Item Name",
      accessor: 'details',
      Cell: ItemNameCell,
    },
    {
      Header: "Quantity",
      accessor: 'quantity',
      Cell: ItemQuantityCell,
    },
  ], [orderFromApi.data])

  return (
      <Fragment>

        { orderItemData == null || orderItemsFromApi.loading || orderData == null || customers.data == null ?
            <Loader /> :
            <>
              <div className={"flex"}>
                <div className={"p-4 bg-white m-4 shadow sm:rounded-lg"}>
                  <h3 className={"text-xl font-semibold mb-4 leading-6 text-gray-900"}>Details</h3>
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Sales Order Number
                      </div>
                      <div className={"text-gray-500"}>
                        {orderData.vtiger_salesorder_no}
                      </div>
                    </div>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Organization
                      </div>
                      <a href={`/customer/${orderData.customer_id}`} className="hover:underline text-sm text-orange-500">
                        {customers.data ? customers.data.find(u => u.id === orderData.customer_id).name: orderData.customer_id}
                      </a>
                    </div>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Status
                      </div>
                      <div className={"text-gray-500"}>
                        {orderData.vtiger_sostatus}
                      </div>
                    </div>
                    <div className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Last Sync Time
                      </div>
                      <div className={"text-gray-500"}>
                        {new Date(orderData.updated_at).toLocaleString()}
                      </div>
                    </div>
                  </div>
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Vtiger Link
                      </div>
                      <a className={"relative block text-sm hover:underline text-orange-500"}
                         target="_blank"
                         href={orderData.external_link}>
                        {orderData.external_link}
                      </a>
                    </div>
                  </div>
                  <div className={"flex gap-8"}>
                    <div  className={"flex-col mt-2"}>
                      <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                        Google Drive Link
                      </div>
                      {orderData.files_link ? <a className={"relative block text-sm hover:underline text-orange-500"}
                                                 target="_blank"
                                                 href={orderData.files_link}>
                        {orderData.files_link}
                      </a> : '-'}
                    </div>
                  </div>
                </div>
              </div>

              <h3 className={"text-xl font-semibold mb-4 mt-8 leading-6 text-gray-900"}>Line Items</h3>
              <ReactTable columns={columns} data={orderItemData} />
              <CreateDataSpecModal open={openDataSpecModal}
                                   setOpen={setOpenDataSpecModal}
                                   afterCreate={(data) => {
                                     navigate(`/dataSpec/${data.id}`);
                                   }}
                                   linkCallback={async (dsId) => {
                                     const response = await axios({

                                       method: 'post',
                                       url: '/api/orderItem/link/' + orderItemId,
                                       data: {data: dsId},

                                     });
                                     setOpenDataSpecModal(false);

                                     const itemsResponse = await axios({

                                       method: 'get',
                                       url: '/api/orderItem/' + orderId,

                                     });
                                     setOrderItemData(itemsResponse.data.data);
                                   }}
                                   orderItemId={orderItemId}
                                   customerId={orderData.customer_id}
                                   allowExistingLink={true}
              />

              <ExportClientDataModal open={openExportClientDataModal}
                                  setOpen={setOpenExportClientDataModal}
                                  afterCreate={(data) => {
                                    navigate(`/dataSpec/${data.id}`);
                                  }}
                                  linkCallback={async (dsId) => {
                                    const response = await axios({

                                      method: 'post',
                                      url: '/api/orderItem/link/' + orderItemId,
                                      data: {data: dsId},

                                    });
                                    setOpenExportClientDataModal(false);

                                    const itemsResponse = await axios({

                                      method: 'get',
                                      url: '/api/orderItem/' + orderId,

                                    });
                                    setOrderItemData(itemsResponse.data.data);
                                  }}
                                  orderItemId={orderItemId}
                                  customerId={orderData.customer_id}
                                  allowExistingLink={true}
                                  exportFileName={exportFileName}
              />

              <ImportFactoryModal open={openImportFactoryModal}
                                  setOpen={setOpenImportFactoryModal}
                                  afterCreate={(data) => {
                                    navigate(`/dataSpec/${data.id}`);
                                  }}
                                  linkCallback={async (dsId) => {
                                    const response = await axios({

                                      method: 'post',
                                      url: '/api/orderItem/link/' + orderItemId,
                                      data: {data: dsId},

                                    });
                                    setOpenImportFactoryModal(false);

                                    const itemsResponse = await axios({

                                      method: 'get',
                                      url: '/api/orderItem/' + orderId,

                                    });
                                    setOrderItemData(itemsResponse.data.data);
                                  }}
                                  orderItemId={orderItemId}
                                  customerId={orderData.customer_id}
                                  allowExistingLink={true}
                                  isStandalone={isStandalone}
                                  customerOrdersId={customerOrdersId}
              />

              <ImportBundleIDModal open={openImportBundleIDModal}
                                  setOpen={setOpenImportBundleIDModal}
                                  afterCreate={(data) => {
                                    navigate(`/dataSpec/${data.id}`);
                                  }}
                                  linkCallback={async (dsId) => {
                                    const response = await axios({

                                      method: 'post',
                                      url: '/api/orderItem/link/' + orderItemId,
                                      data: {data: dsId},

                                    });
                                    setOpenImportBundleIDModal(false);

                                    const itemsResponse = await axios({

                                      method: 'get',
                                      url: '/api/orderItem/' + orderId,

                                    });
                                    setOrderItemData(itemsResponse.data.data);
                                  }}
                                  orderItemId={orderItemId}
                                  customerId={orderData.customer_id}
                                  allowExistingLink={true}
                                  isStandalone={isStandalone}
                                  customerOrdersId={customerOrdersId}
              />
            </>
        }
      </Fragment>
  )
}
