import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AuthContext, ErrorMessage, Form, useAPI, ViewContext} from 'components/lib';
import fileDownload from "js-file-download";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function ExportClientDataModal(props) {
    const viewContext = useContext(ViewContext);
    const [ExportSeparatorOption, setExportSeparatorOption] = useState(false);
    const [includeBundleId, setIncludeBundleId] = useState(false);
    const [customerEmail, setCustomerEmail] = useState('');
    const [exportFileName, setExportFileName] = useState(props.exportFileName);
    const [ExportDelimiter, setExportDelimiter] = useState('');
    const [exportOtherDelimiter, setExportOtherDelimiter] = useState('');
    const [exportQuate, setExportQuote] = useState('');
    const [exportError_ss, setExportError_ss] = useState(false);

    const cancelButtonRef = useRef(null);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setExportFileName(props.exportFileName);
    }, [props.exportFileName]);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="importFactoryFeature exportDataForClient">
                                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                                        Export Data for Client
                                    </Dialog.Title>
                                    {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}

                                    <div className={"mt-8 mx-8"}>
                                        <div className={"SeparatorOption mb-4"} >
                                            <Form
                                                className={"p-1 bg-white space-y-2 sm:rounded-lg"}
                                                flex={true}
                                                url={`/api/exportDataForClient/`}
                                                method='POST'
                                                buttonTextSm='Export data file'
                                                updateOnChange={true}
                                                cancel={ e => props.setOpen(false) }
                                                onChange={(e) => {

                                                        if(e.input==='customerEmail') {
                                                            setCustomerEmail(e.value);
                                                        }
                                                        if(e.input==='exportFileName') {
                                                            setExportFileName(e.value);
                                                        }
                                                        if(e.input==='includeBundleId') {
                                                            setIncludeBundleId(e.value);
                                                        }
                                                        if(e.input==='importSeparation'){
                                                            setExportSeparatorOption(e.value);
                                                        }
                                                        else{
                                                            if(e.input==="quate"){
                                                                if(e.value===''){
                                                                    setExportQuote(null);
                                                                }else{
                                                                    if(e.value==="(" || e.value===")"){
                                                                        setExportError_ss(true);
                                                                    }else{
                                                                        setExportError_ss(false);
                                                                    }
                                                                    console.log(e.value)
                                                                    setExportQuote(e.value);
                                                                }
                                                            }else{
                                                                if(e.input==='otherSeperator'){
                                                                    setExportOtherDelimiter(e.value);
                                                                }else{
                                                                    setExportDelimiter(e.value);
                                                                    setExportOtherDelimiter('');
                                                                }

                                                            }
                                                        }

                                                }}
                                                callback={(s) => {
                                                    if(s.data.length===undefined) {
                                                        viewContext.notification.show("No data present for export file in Database. Try to export factory file first.", 'error', true);
                                                    }else {
                                                        fileDownload(s.data, `${exportFileName}.csv`);
                                                        props.setOpen(false)
                                                        viewContext.notification.show("The data export is successful, and an email has been sent to the client.", 'success', true);
                                                    }
                                                }}
                                                data={{
                                                    orderID: {
                                                        label: 'orderID',
                                                        type: 'hidden',
                                                        required: true,
                                                        value: props.orderItemId,
                                                    },
                                                    exportFileName:{
                                                        label: 'Export file name',
                                                        type: 'text',
                                                        classNames:'exportFilenameInput',
                                                        value: exportFileName,
                                                    },
                                                    customerEmail:{
                                                        label: 'Customer Email(Optional)',
                                                        type: 'text',
                                                        classNames:'exportFilenameInput',
                                                        value: customerEmail,
                                                    },
                                                    includeBundleId: {
                                                        label: 'Include bundle Id',
                                                        type: 'singleCheckbox',
                                                        value: includeBundleId,
                                                        required: false,
                                                        errorMessage: 'Please select an option',
                                                    },
                                                    importSeparation: {
                                                        label: 'Separator Options',
                                                        type: 'singleCheckbox',
                                                        value: ExportSeparatorOption,
                                                        required: false,
                                                        errorMessage: 'Please select an option',
                                                    },
                                                    separator_ss: {
                                                        type: 'radio',
                                                        options: ["Tab", "Comma", "Semicolon", "Space", "Other"],
                                                        required: false,
                                                        errorMessage: 'Please select an option',
                                                        value:ExportDelimiter,
                                                        label: 'Separated by',
                                                        isHidden: !ExportSeparatorOption,
                                                    },
                                                    otherSeperator: {
                                                        label: '',
                                                        type: 'text',
                                                        value: exportOtherDelimiter,
                                                        isHidden:ExportDelimiter!=="Other",
                                                        required: false,
                                                        errorMessage: 'Separated by',
                                                    },
                                                    quateValue: {
                                                        label: 'String Delimiter',
                                                        type: 'text',
                                                        value: exportQuate,
                                                        required: false,
                                                        isHidden: !ExportSeparatorOption,
                                                        errorMessage: 'Please enter string delimiter',
                                                    },

                                                }}>
                                            </Form>

                                            <span className="error_ss" style={{display: exportError_ss === true ? 'block' : 'none'}}>Unsupported String Delimeter</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
