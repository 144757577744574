import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Label, Error, Icon, ClassHelper } from 'components/lib';
import Style from './input.tailwind.js';

export function TextInput(props){

  const error = props.errorMessage || 'Please enter a value';

  function validate(e){

    let value = e ? e.target.value : '';

    // if (props.validate && typeof props.validate === 'function') {
    //   props.onChange?.(props.name, value, props.validate(value));
    //   return;
    // }

    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '')
      valid = false;

    // input isn't required and value is blank
    if (!props.required && value !== '') // jchu: Made this so it didn't make empty values as 'valid' (green check)
      valid = true;

    if (props.required && value !== '')
      valid = true;

    // update the parent form
    props.onChange?.(props.name, value, valid);

  }

  // style
  const textStyle = ClassHelper(Style, {

    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning

  });

  return(
    <div className={ Style.input + ' ' + props.outerClassName }>

      { props.label &&
        <Label
          text={ props.label }
          required={ props.required }
          for={ props.name }
        /> }
      {props.tooltip &&
      <div className="flex mb-2 -mt-1 text-gray-400 items-center">
          <Icon
            image='info'
            size={ 14 }
          />
          <p className="ml-1 text-sm text-gray-400">

            {props.tooltip}
          </p>
        </div>
      }

      { props.type === 'textarea' ?

        <textarea
          id={ props.id }
          name={ props.name }
          value={ props.value }
          className={ textStyle }
          placeholder={ props.placeholder }
          onChange={ e => props.onChange?.(props.name, e.target.value, undefined) }
          onBlur={ e => validate(e) }
          disabled={props.readonly}
        >
        </textarea>

        :

        <input
          type='text'
          id={ props.id }
          name={ props.name }
          value={ props.value || '' }
          // className={ textStyle + " transition duration-300"  }
          className={ textStyle  }
          placeholder={ props.placeholder }
          autoComplete={ props.autoComplete }
          onChange={ e => props.onChange?.(props.name, e.target.value, undefined) }
          onBlur={ e => validate(e) }
          disabled={props.readonly}
        />
      }

      { props.valid === false &&
        <Error className={props.errorClassName ? props.errorClassName : 'relative'} message={ error }/> }

      { props.valid === true &&
        <Icon
          image='check'
          color='#8CC57D'
          className={ Style.successIcon }
          size={ 16 }
        />}

    </div>
  );
}
