import 'react-app-polyfill/ie9';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/app';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <App></App>,
  document.getElementById('root')
);

const welcome = () => console.log('Welcome to Datastand 🚀🟠')
welcome('de529c70-eb80-4dfb-9540-5075db7545bf')
