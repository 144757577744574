import React from 'react';
import { Icon } from 'components/lib';
import Style from "../../../components/form/file/file.tailwind";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Tabs(props) {
  return (
    <div className={props.className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-orange-200 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={props.tabs.find((tab) => tab.id === props.activeTab).name}
        >
          {props.tabs.map((tab) => (
            <option key={tab.key}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="relative z-0 rounded-lg border border-gray-300 flex divide-x divide-gray-200" aria-label="Tabs">
          {props.preButtons.map((button, idx) => (
            <a
              key={button.key}
              // href={tab.href}
              onClick={() => button.onClick()}
              className={classNames(
                'text-gray-500 hover:text-gray-700 bg-gray-50',
                idx === 0 ? 'rounded-l-lg' : '',
                'group cursor-pointer relative min-w-0 flex-1 items-center justify-center overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-100 focus:z-10'
              )}
            >
              <span className={"align-bottom"}>{button.icon && <Icon image={ button.icon } size={18} className={ Style.labelIcon + ' leading-5 align-middle' }/>}</span>
              <span>{button.name}</span>
              <span
                aria-hidden="true"
                className={classNames('bg-transparent absolute inset-x-0 bottom-0 h-0.5')}
              />
            </a>
          ))}
          {props.tabs.map((tab, tabIdx) => (
            <a
              key={tab.key}
              // href={tab.href}
              onClick={() => props.handleTabClick(tab.id, tab.key)}
              className={classNames(
                tab.id === props.activeTab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 && props.preButtons.length === 0 ? 'rounded-l-lg' : '',
                tabIdx === props.tabs.length - 1 && props.postButtons.length === 0? 'rounded-r-lg' : '',
                'group cursor-pointer relative min-w-0 flex-1 items-center justify-center overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.id === props.activeTab ? 'page' : undefined}
            >
              <span className={"align-bottom"}>{tab.icon && <Icon image={ tab.icon } size={18} className={ Style.labelIcon + ' leading-5 align-middle' }/>}</span>
              <span>{tab.name} {['NO_DATA', 'ADD_PRINT_TAB_KEY', 'ADD_CHIP_TAB_KEY'].includes(tab.key) ? '' :
                <span
                  className={classNames(
                    tab.badge > 0 ? 'bg-orange-100 text-orange-600' : 'bg-gray-100 text-gray-900',
                    'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                  )}
                >
                    {tab.badge}
                  </span>}
              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.id === props.activeTab ? 'bg-brand-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </a>
          ))}
          {props.postButtons.map((button, idx) => (
            <a
              key={button.key}
              // href={tab.href}
              onClick={() => button.onClick()}
              className={classNames(
                'text-gray-500 hover:text-gray-700 bg-gray-50',
                idx === props.postButtons.length - 1 ? 'rounded-r-lg' : '',
                'group cursor-pointer relative min-w-0 flex-1 items-center justify-center overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-100 focus:z-10'
              )}
            >
              <span className={"align-bottom"}>{button.icon && <Icon image={ button.icon } size={18} className={ Style.labelIcon + ' leading-5 align-middle' }/>}</span>
              <span>{button.name}</span>
              <span
                aria-hidden="true"
                className={classNames('bg-transparent absolute inset-x-0 bottom-0 h-0.5')}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>
  )
}
