/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  Card, Stat, ProgressBar, Chart, Table,
  Message, Grid, Animate, Feedback, useAPI, Button, AuthContext, Loader, ReactTable, Form, Icon
} from 'components/lib';
import Style from "../../components/hero/hero.tailwind";
import {CreateDataSpecModal} from "../dataspec/shared/CreateDataSpecModal";
import axios from "axios";

export function AdminPanel(props){
  // get User context
  const context = useContext(AuthContext);

  const isAdmin = useAPI('/api/adminPanel/admin/' + context.user?.user_id);
  const users = useAPI('/api/user/');

  const [accountWhitelist, setAccountWhitelist] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [whitelistEmail, setWhitelistEmail] = useState('');
  const [whitelistError, setWhitelistError] = useState(undefined);
  const [adminInputUserId, setAdminInputUserId] = useState(undefined);
  const [adminError, setAdminError] = useState(undefined);

  useEffect(() => {
    async function asyncFunction() {
      if (isAdmin.data && isAdmin.data.isAdmin) {
        const response = await axios({

          method: 'get',
          url: '/api/adminPanel/admin',
          data: {actionUserId: context.user?.user_id},

        });

        console.log(response);
        if (response.status === 200) {
          // Update state
          setAdmins(response.data.data);
        }

        const response2 = await axios({

          method: 'get',
          url: '/api/adminPanel/whitelist',
          data: {actionUserId: context.user?.user_id},

        });

        console.log(response2);
        if (response2.status === 200) {
          // Update state
          setAccountWhitelist(response2.data.data);
        }
      }
    }
    asyncFunction();
  }, [isAdmin]);

  async function createWhitelistAccount() {
    if(!whitelistEmail) {
      setWhitelistError('Valid email address required.');
      return;
    }
    setWhitelistError(undefined);

    const response = await axios({

      method: 'post',
      url: `/api/adminPanel/whitelist/`,
      data: {email: whitelistEmail}

    });
    console.log(response);
    if (response.status === 200) {
      window.location.reload();
    }
    else {
      setWhitelistError("Error when adding whitelist account.");
    }
  }

  async function createAdmin() {
    if(!adminInputUserId) {
      setAdminError('Valid user required.');
      return;
    }
    setAdminError(undefined);

    const response = await axios({

      method: 'post',
      url: `/api/adminPanel/admin/`,
      data: {userId: adminInputUserId}

    });
    console.log(response);
    if (response.status === 200) {
      window.location.reload();
    }
    else {
      setAdminError("Error when promoting admin account.");
    }
  }

  async function deleteWhitelistAccount(id) {
    if(!id) {
      return;
    }

    const response = await axios({

      method: 'delete',
      url: `/api/adminPanel/whitelist/${id}`

    });
    console.log(response);
    if (response.status === 200) {
      window.location.reload();
    }
  }

  async function deleteAdmin(id) {
    if(!id) {
      return;
    }

    const response = await axios({

      method: 'delete',
      url: `/api/adminPanel/admin/${id}`

    });
    console.log(response);
    if (response.status === 200) {
      window.location.reload();
    }
  }

  return (
    <Fragment>

      { !isAdmin.data || !users.data ?
        <Loader /> :
        <>
          {!isAdmin.data.isAdmin ?
            <div>User is not an admin</div> :
            <>
              <div className={"w-96"}>
                <input type={'text'} className={'w-full p-3 rounded bg-white border border-solid border-gray-300 focus:bg-slate-50 appearance-none disabled:opacity-50'}
                       placeholder={"Email to whitelist"}
                       value={whitelistEmail} onChange={(event) => {
                  setWhitelistEmail(event.target.value);
                }}
                />
                <Button action={async () => {
                  await createWhitelistAccount();
                }}
                        text='Create Whitelist Email' small
                        className={ Style.button + " mt-2 mr-2 relative bg-orange-500 hover:bg-orange-700 w-full "}/>
                {whitelistError && <div className={"flex text-red-500"}>{whitelistError}</div>}
            </div>
              {accountWhitelist && (<div className={"mt-4"}>
                <h3 className={"text-lg font-medium"}>List of Whitelist Accounts ({accountWhitelist.length} total)</h3>
                <ol className={"list-decimal ml-8"}>
                {accountWhitelist.map(aw => (
                  <li key={aw.id}>{aw.email} <button
                    type="button"
                    className="ml-6 inline-flex align-top items-center rounded-md text-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
                    onClick={async () => {
                      await deleteWhitelistAccount(aw.id);
                    }}
                  >
                    <Icon image={'trash'} size={12} className={Style.labelIcon}/>
                    Delete
                  </button></li>
                ))}
                </ol>

              </div>)}

              <div className={"w-96 mt-12"}>
                <Form
                  updateOnChange={true}
                  onChange={(e) => {
                    setAdminInputUserId(e.value);
                  }}
                  className={"rounded-sm"}
                  flex={false}
                  data={{
                    customer_id: {
                      label: "Promote User to Admin",
                      type: 'select',
                      options: users.data.map(x => {return {value: x.id, label: `${x.email}` }}),
                      required: false,
                      value: adminInputUserId || '',
                      errorMessage: 'Please select a user'
                    },
                  }}>
                </Form>
                <Button action={async () => {
                  await createAdmin();
                }}
                        text='Promote User to Admin' small
                        className={ Style.button + " mt-2 mr-2 relative bg-orange-500 hover:bg-orange-700 w-full "}/>
                {adminError && <div className={"flex text-red-500"}>{adminError}</div>}
              </div>
              {admins && (<div className={"mt-4"}>
                <h3 className={"text-lg font-medium"}>List of Admins ({admins.length} total)</h3>
                <ol className={"list-decimal ml-8"}>
                  {admins.map(admin => (
                    <li key={admin.id}>{users.data.find(u => u.id === admin.user_id).email}<button
                      type="button"
                      className="ml-6 inline-flex align-top items-center rounded-md text-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
                      onClick={async () => {
                        await deleteAdmin(admin.id);
                      }}
                    >
                      <Icon image={'trash'} size={12} className={Style.labelIcon}/>
                      Delete
                    </button></li>
                  ))}
                </ol>
              </div>)}
            </>
          }
        </>
      }
    </Fragment>
  )
}
