import React from 'react';
import {Label, Error, ClassHelper, Icon} from 'components/lib';
import Style from './select.tailwind.js';

export function Select(props){

  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  // set the default
  if ((!props.default || props.isNullable) && options?.length){

    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: 'Please select an option' });

  }

  function change(e){

    let value = e ? e.target?.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value)

  }

  const wrapperStyle = ClassHelper(Style, {

    className: props.className,
    success: props.valid === true,
    errorWrapper: props.valid === false,
    warningWrapper: props.warning,

  });

  const selectStyle = ClassHelper(Style, {

    select: true,
    error: props.valid === false,
    warning: props.warning,

  });

  return(
    <div className={ Style.input + props.outerClassName }>

      <Label
        text={ props.label }
        required={ props.required }
        for={ props.name }
        compact={ props.compact }
      />
      {props.tooltip &&
      <div className="flex mb-2 -mt-1 text-gray-400 items-center">
        <Icon
          image='info'
          size={ 14 }
        />
        <p className="ml-1 text-sm text-gray-400">

          {props.tooltip}
        </p>
      </div>
      }

      <div className={ wrapperStyle }>

        <select
          className={ selectStyle + (props.readonly ? ' cursor-not-allowed' : props.compact ? ' pl-2 pr-8 py-1' : '') }
          // defaultValue={ props.default }
          value={ props.value }
          onChange={ e => change(e) }
          id={ props.name }
          disabled={props.readonly}>

          { options?.map(option => {
            return (
              <option
                key={ option.value }
                value={ option.value }>
                { option.label }
              </option>
            );
          })}

        </select>

        { props.valid === false && <Error className={props.errorClassName ? props.errorClassName : 'relative'} message={ error }/> }

      </div>
    </div>
  );
}
