import React, {useEffect, useState} from 'react';
import {Label, ClassHelper, Legend, Grid, Error, Icon} from 'components/lib';
import Style from './checkbox.tailwind.js';

export function SingleCheckbox(props){

  // state
  const [on, setOn] = useState(props.value);  // state

  // If initial value is changed by parent, this ensure that it gets updated
  useEffect(() => {
    setOn(props.value);
  }, [props.value]);


  function toggle(){

    setOn(!on);
    props.onChange(props.name, !on, true);

  }

  const fieldsetStyle = ClassHelper(Style, {

    // error: valid === false,
    className: props.className,

  });

  return(
    <fieldset className={ fieldsetStyle + props.outerClassName}>

      <Legend
        valid={ true }
        text={ props.label }
        required={ props.required }
      />
      {props.tooltip &&
      <div className="flex mb-2 -mt-1 text-gray-400 items-center">
        <Icon
          image='info'
          size={ 14 }
        />
        <p className="ml-1 text-sm text-gray-400">

          {props.tooltip}
        </p>
      </div>
      }

      <div>
        <input
          type='checkbox'
          name={ props.name }
          id={ props.name }
          value={ props.value }
          className={ Style.input + (props.readonly ? ' cursor-not-allowed' : '')  }
          checked={ on ? 'checked' : '' }
          onChange={ e => toggle()}
          disabled={props.readonly}
        />
      </div>

      {/*{ !valid &&*/}
      {/*<Error message={ error } className={ Style.errorMessage }/> }*/}

    </fieldset>

  );
}
