const Style = {

  hero: `relative text-white mb-10 md:text-center pt-8 md:pt-12 before:bg-gradient-to-b 
    before:from-slate-700 before:to-slate-800 before:absolute before:-top-36 before:left-0 
    before:bottom-0 before:right-0 before:-z-10`,

  blurb: 'mb-10 mx-auto',
  title: 'block text-3xl md:text-4xl font-bold mb-4',
  tagline: 'block text-lg m0 mb-4',
  image: '-mb-12 shadow-lg',
  // button: 'md:mx-auto',

}

export default Style;
