
import React from 'react';
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Icon, Label, Error, ClassHelper } from 'components/lib';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function CustomSelect(props){

  const [selectedOption, setSelectedOption] =
    useState(props.value ?
      { value: props.value, label: props.options.find(o => o.value === props.value).label, secondaryText: props.options.find(o => o.value === props.value).secondaryText} :
      { value: 'unselected', label: 'Please select an option' });

  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  // set the default
  if (!props.default && options?.length){

    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: 'Please select an option' });

  }

  function change(selection){

    let value = selection ? selection.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value)

  }

  return(
    <div className={props.outerClassName}>
      <Listbox value={selectedOption} onChange={(selection) => {
        change(selection);
        setSelectedOption(selection);
      }}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">{props.label}</Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                <span className="w-full inline-flex truncate">
                  <span className="truncate">{selectedOption.label}</span>
                  <span className="ml-2 truncate text-gray-500">{selectedOption.secondaryText}</span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <Icon image="chevron-down" className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-[999] mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-orange-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      disabled={!option.value || option.value === 'unselected'}
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex">
                            <span className={classNames((selectedOption.value === option.value && selectedOption.value !== 'unselected') || selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                              {option.label}
                            </span>
                            <span className={classNames(active ? 'text-orange-200' : 'text-gray-500', 'ml-2 truncate')}>
                              {option.secondaryText}
                            </span>
                          </div>

                          {(selectedOption.value === option.value && selectedOption.value !== 'unselected') || selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-orange-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <Icon image="check" className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
