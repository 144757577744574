const Style = {

  base: 'relative block uppercase',
  normal: 'text-sm mb-1 ',
  compact: 'text-xs mb-0',
  required: `after:text-red-500 after:ml-1 after:font-semibold after:content-['*']`

}

export default Style;
