

// Returns true if any chip configs have variable data (data source or chip UID)
export function hasChipVariableData(chipConfigs) {
  if (!chipConfigs || chipConfigs.length === 0) {
    return false;
  }
  let hasVariableData = false;
  chipConfigs.forEach(cc => {
    if (cc.dataRecords) {
      cc.dataRecords.forEach(dr => {
        if (dr.use_chip_uid || (dr.data_sources && dr.data_sources.length > 0)) {
          hasVariableData = true;
        }
      });
    }
  });
  return hasVariableData;
}
// Returns true if print config has variable data (data source or chip UID)
export function hasPrintingVariableData(printConfig) {
  if (!printConfig) {
    return false;
  }
  let printHasVariableData = false;
  printConfig.printRecords.forEach(pr => {
    if (pr.use_chip_uid || (pr.data_sources && pr.data_sources.length > 0)) {
      printHasVariableData = true;
    }
  });
  return printHasVariableData;
}
// Return true if print or chip configs reference chip UID at least once
export function isChipUidReferenced(printConfig, chipConfigs) {
  let printHasUid = false;
  let chipHasUid = false;
  if (printConfig) {
    printConfig.printRecords.forEach(pr => {
      if (pr.use_chip_uid) {
        printHasUid = true;
      }
    });
  }
  chipConfigs.forEach(cc => {
    if (cc.dataRecords) {
      cc.dataRecords.forEach(dr => {
        if (dr.use_chip_uid) {
          chipHasUid = true;
        }
      });
    }
  });
  if (chipHasUid || printHasUid) {
    return true;
  }
  return false;
}

// Return true if both print and encoding record have at least 1 reference to a data source (doesn't have to match)
export function hasSharedDataSource(printConfig, chipConfigs) {
  if (!printConfig || printConfig.printRecords.length === 0 || chipConfigs.length === 0 ||
      chipConfigs.flatMap(cc => cc.dataRecords).length === 0) {
    return false;
  }
  let printHasUid = false;
  let chipHasUid = false;
  let allDataRecordDataSources = chipConfigs.flatMap(cc => cc.dataRecords.flatMap(dr => dr.data_sources || []));
  let allPrintRecordDataSources = printConfig.printRecords.flatMap(pr => pr.data_sources || []);
  printConfig.printRecords.forEach(pr => {
    if (pr.use_chip_uid) {
      printHasUid = true;
    }
  });
  chipConfigs.forEach(cc => {
    if (cc.dataRecords) {
      cc.dataRecords.forEach(dr => {
        if (dr.use_chip_uid) {
          chipHasUid = true;
        }
      });
    }
  });
  if ((allDataRecordDataSources.length > 0 || chipHasUid) && (allPrintRecordDataSources.length > 0 || printHasUid)) {
    return true;
  }
  return false;
}

// Returns true if any chip configs have data locking)
export function hasChipDataLocking(chipConfigs) {
  if (!chipConfigs || chipConfigs.length === 0) {
    return false;
  }
  let hasDataLocking = false;
  chipConfigs.forEach(cc => {
    if (cc.lock_all) {
      hasDataLocking = true;
    }
  });
  return hasDataLocking;
}

// Returns true if any chip configs have collect uid during production enable)
export function hasChipCollectUID(chipConfigs) {
  if (!chipConfigs || chipConfigs.length === 0) {
    return false;
  }
  let hasCollectUID = false;
  chipConfigs.forEach(cc => {
    if (cc.collect_chip_uid) {
      hasCollectUID = true;
    }
  });
  return hasCollectUID;
}
