/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { useContext, useState } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import Style from './app.module.scss';

export const AppLayoutContext = React.createContext();

export function AppLayout(props){

  // context & style
  const context = useContext(AuthContext);

  const [navButtons, setNavButtons] = useState([]);

  return (
    <AppLayoutContext.Provider value={ [navButtons, setNavButtons] }>
      <AppNav
        items={[
          // { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'Data Specs', icon: 'file-text', link: '/dataspec', },
          { label: 'Orders', icon: 'shopping-bag', link: '/orders' },
          { label: 'Customers', icon: 'briefcase', link: '/customers' },
          { label: 'Admin Panel', icon: 'lock', link: '/adminPanel' },
          // { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={ Style.app }>
        <Header title={ props.title } className={"sticky top-0 shadow"}>
          <User/>
        </Header>

        { <props.children {...props.data }/> }

      </main>
    </AppLayoutContext.Provider>
  );
}
