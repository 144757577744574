import React, {Fragment, useContext, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AuthContext, ErrorMessage, Form, useAPI, ViewContext} from 'components/lib';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function UploadOffensiveWords(props) {
    const context = useContext(AuthContext);
    const viewContext = useContext(ViewContext);
    const [uploadedDataFile, setUploadedDataFile] = useState(undefined);
    const [exportError_ss, setExportError_ss] = useState(false);
    const cancelButtonRef = useRef(null);
    const [errors, setErrors] = useState([]);


    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>
                                <div className="importFactoryFeature">
                                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                                        Upload Offensive Words!
                                    </Dialog.Title>
                                    {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}

                                    <div className={"mt-8 mx-8"}>
                                        <div className={"SeparatorOption mb-4"} >
                                            <Form
                                                className={"p-1 bg-white space-y-2 sm:rounded-lg"}
                                                flex={true}
                                                url={`/api/importOffensiveWord`}
                                                method='POST'
                                                buttonTextSm='Upload data file'
                                                updateOnChange={true}
                                                cancel={ e => props.setOpen(false) }
                                                onChange={(e) => {
                                                    if(e.input==='dataFile'){
                                                        setUploadedDataFile(e.value);
                                                    }
                                                }}
                                                callback={(s) => {
                                                    if(s.data.success) {
                                                        props.setOpen(false)
                                                        viewContext.notification.show("Offensive words uploaded successfully!", 'success', true);
                                                        // window.location.reload();
                                                    }else {
                                                        props.setOpen(false)
                                                        viewContext.notification.show(s.data.err, 'error', true);
                                                    }
                                                }}
                                                data={{
                                                    dataFile: {
                                                        label: '',
                                                        type: 'file',
                                                        required: true,
                                                        value: uploadedDataFile,
                                                        errorMessage: 'Please upload a file',
                                                    },
                                                }}>
                                            </Form>

                                            <span className="error_ss" style={{display: exportError_ss === true ? 'block' : 'none'}}>Unsupported String Delimeter</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
