/***
 *
 *   ORDERS
 *   View sales orders
 *
 **********/

import React, {Fragment, useContext, useEffect} from 'react';
import {
  AppLayoutContext,
  DateCell,
  HyperlinkNewTabCell,
  Loader,
  ReactTable,
  OrderNumberCell,
  useAPI
} from 'components/lib';

export function Orders(props){

  const orders = useAPI('/api/orders');
  const customers = useAPI('/api/customer/');
  console.log(orders);

  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Order Number",
      accessor: 'vtiger_salesorder_no',
      Cell: OrderNumberCell,
    },
    {
      Header: "Organization",
      id: "customer_id",
      accessor: (row) => {
        return row && row.customer_id && customers.data ? customers.data.find(u => u.id === row.customer_id).name: row.customer_id
      },
    },
    {
      Header: "Status",
      accessor: 'vtiger_sostatus',
      // Cell: StatusPill,
    },
    {
      Header: "vTiger Created Time",
      accessor: 'vtiger_createdtime',
      Cell: DateCell,
    },
    {
      Header: "vTiger Modified Time",
      accessor: 'vtiger_modifiedtime',
      Cell: DateCell,
    },
    {
      Header: "Last Sync",
      accessor: 'updated_at',
      Cell: DateCell,
    },
    {
      Header: "vTiger Link",
      accessor: 'external_link',
      Cell: HyperlinkNewTabCell,
    },
  ], [customers])

  return (
    <Fragment>

      { orders.data == null || orders.loading || customers.loading || customers.data == null ?
        <Loader /> :
        // <Table data={orders.data} search={true} sort={true} loading={orders.loading} badge='' ></Table>
        <ReactTable columns={columns} data={orders.data} initialPageSize={20} />
      }
    </Fragment>
  )
}
