/***
 *
 *   CREATE DATA SPEC
 *   Create a data specification
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {AppLayoutContext, ErrorMessage, Form} from 'components/lib';
import {Tabs} from './shared/Tabs';
import {PrintConfig} from './shared/PrintConfig';
import {ChipConfig} from './shared/ChipConfig';
import {ChipModal} from './shared/ChipModal';
import axios from "axios";

export function CreateDataSpec(props){

  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let [activeTab, setActiveTab] = useState(0);
  let [tabs, setTabs] = useState([
    { name: 'Printing', href: '#', id: 0, key:'print_tab_key_cds', badge: 0, current: true },
    { name: 'Add Chip', icon: 'plus', href: '#', id: -1, key:'add_chip_key_cds', current: false },
  ]);
  const [errors, setErrors] = useState([]);
  let [dataSpec, setDataSpec] = useState({
    number: '',
    description: '',
    is_locked: false,
    data_deliverables_customer: '',
    data_deliverables_internal: '',
    customer: '',
    assigned_to: '',
    created_by: '',
  });

  let [printConfig, setPrintConfig] = useState({
    instructions: '',
    printRecords: [],
  });
  let [chipConfigs, setChipConfigs] = useState([]);

  // Add/edit chip modal state
  function getDefaultChipData() {
    return {
      instructions: '',
      lock_all: false,
      password: '',
      dataRecords: [],
    }
  }
  const [openChipModal, setOpenChipModal] = useState(false);
  // This is the data that the modal loads with
  const [modalContext, setModalContext] = useState({ data: getDefaultChipData(), isEdit: false });

  function validateDataSpec (obj) {
    let errors = [];
    if (!obj.dataSpec || !obj.dataSpec.number) {
      errors.push("DS Number is required.");
    }


    if (errors.length > 0) {
      return {errors: errors, success: false};
    }
    return {errors: [], success: true};
  }

  async function createDataSpec(dataSpec, printConfig, chipConfigs) {
    const validationResult = validateDataSpec({dataSpec, printConfig, chipConfigs});

    if (validationResult.errors && validationResult.errors.length > 0) {
      setErrors(validationResult.errors);
    }
    else { // Passed validation, send request
      setErrors([]);
      const finalPrintConfig = {
        ...printConfig,
      }
      const finalChipConfigs = chipConfigs.map(cc => {
        return cc;
      });

      const response = await axios({

        method: 'post',
        url: '/api/dataSpec/' + searchParams.get('parent'),
        data: {dataSpec, printConfig: finalPrintConfig, chipConfigs: finalChipConfigs}

      });

      console.log(response);
      if (response.status === 200) {
        // Navigate to view/edit data spec
        navigate(`/dataspec/${response.data.data.id}`);
      }
    }

  }

  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([{text: 'Create', color: 'orange', key: 'create_data_spec_button', onClick: () => createDataSpec(dataSpec, printConfig, chipConfigs)}]);
  }, [dataSpec, printConfig, chipConfigs]);

  // Update tabs when chipConfigs change
  useEffect(() => {
    if (chipConfigs && printConfig) {
      // convert chipConfigs to tabs
      const chipTabs = chipConfigs.sort((a,b) => new Date(a.created_at) - new Date(b.created_at)) // order by created time
        .map((cc,index) => {
          return {
            name: cc.name, href: '#', id: index+1, current:(index+1) === activeTab, key: cc.id, badge: cc.dataRecords.length
          }
        });

      // convert print records to tabs
      const printTab = {
        name: 'Printing',
        href: '#',
        id: 0,
        key: 'PRINTING_TAB_KEY',
        badge: printConfig.printRecords ? printConfig.printRecords.length : 0,
        current: tabs[0].current
      };

      setTabs([printTab, ...chipTabs, tabs[tabs.length-1]]);
    }
  }, [chipConfigs, printConfig]);

  // Update active tab when new chip is added
  useEffect(() => {
    setActiveTab(chipConfigs.length); // This logic relies on Printing tab being there
  }, [chipConfigs.length]);

  function handleTabClick (selectedId) {
    if (selectedId === -1) {
      // handle add chip click
      setModalContext({
        data: getDefaultChipData(),
        isEdit: false,
      });
      setOpenChipModal(true);
    }
    else if (activeTab !== selectedId) {
      setActiveTab(selectedId);
    }
  }

  return (
    <Fragment>
      {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}
      <div className={"p-4 pb-6 bg-white my-4 shadow sm:rounded-lg"}>
        <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Details</h3>
        <Form
            // buttonText='Create'
            // url={'/api/dataSpec' + (searchParams.get('parent') ? '/' + searchParams.get('parent') : '')}
            // method='POST'
            updateOnChange={true}
            onChange={(e) => {
              setDataSpec({
                ...dataSpec,
                [e.input]: e.value
              })
            }}
            className={"space-y-6"}
            flex={true}
            data={{
              customer: {
                label: 'Organization',
                type: 'text',
                required: true,
                value: dataSpec.customer,
                errorMessage: 'Please enter an organization',
              },
              number: {
                label: 'Data Specification Number',
                type: 'text',
                required: true,
                value: dataSpec.number,
                errorMessage: 'Please enter a DS number',
              },
              description: {
                label: 'Description',
                type: 'textarea',
                required: false,
                value: dataSpec.description,
                errorMessage: 'Please enter a description',
              },
              is_locked: {
                label: 'Is Frozen',
                type: 'singleCheckbox',
                required: false,
                value: dataSpec.is_locked,
                errorMessage: '',
              },
              data_deliverables_customer: {
                label: 'Data Deliverables (Customer)',
                type: 'text',
                required: false,
                value: dataSpec.data_deliverables_customer,
                errorMessage: 'Please enter a value',
              },
              data_deliverables_internal: {
                label: 'Data Deliverables (Internal)',
                type: 'text',
                required: false,
                value: dataSpec.data_deliverables_internal,
                errorMessage: 'Please enter a value',
              },
              assigned_to: {
                label: 'Assigned To',
                type: 'text',
                required: false,
                value: dataSpec.assigned_to,
                errorMessage: 'Please select a user',
              },
              created_by: {
                label: 'Created By',
                type: 'text',
                required: false,
                value: dataSpec.created_by,
                errorMessage: 'Please select a user',
              },
            }}>
        </Form>
      </div>

      <div className={"p-4 bg-white my-4 shadow sm:rounded-lg"}>
        <h3 className={"text-xl font-semibold ml-2 mb-6 leading-6 text-gray-900"}>Device Configuration</h3>
        <Tabs className={`${tabs.length > 5 ? '' : tabs.length === 5 ? 'w-5/6' : tabs.length === 4 ? 'w-3/5' : tabs.length === 3 ? 'w-1/2' : tabs.length === 2 ? 'w-2/6' :'w-1/4'}`}
              activeTab={activeTab}
              tabs={tabs}
              handleTabClick={handleTabClick} />

        {
          tabs.find((tab) => tab.id === activeTab).name === 'Printing' ?
            <PrintConfig printConfig={printConfig} setPrintConfig={setPrintConfig} /> :
            <ChipConfig chipConfig={chipConfigs[activeTab-1]} setChipConfig={(chConfig, isDelete) => {
              if (isDelete) {
                setActiveTab(prevActiveTab => prevActiveTab - 1);
                setChipConfigs(prev => prev.filter((cc) => cc.id !== chConfig.id));
              } else {
                setChipConfigs(prev => [...prev.map((cc) => cc.id === chConfig.id ? chConfig : cc)]);
              }
            }} />
        }
      </div>
      <ChipModal open={openChipModal}
                  setOpen={setOpenChipModal}
                  context={modalContext}
                   addChip={(newChip) => {
                     setChipConfigs(prev => [...prev, newChip]);
                   }}
      />
    </Fragment>
  )
}
