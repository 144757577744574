import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {ErrorMessage, Form, Icon, Loader, useAPI} from 'components/lib';
import Style from "../../../components/form/file/file.tailwind";
import {Dialog, Transition} from "@headlessui/react";
import debounce from "lodash.debounce";
import axios from "axios";

export function PrintConfig (props) {
  function getDefaultPrintRecord() {
    return {
      print_config_id: props.printConfig.id,
      instructions: '',
      payload_template: '',
    }
  }
  const printTypes = useAPI('/api/printTypes/');
  const uidFormats = useAPI('/api/uidFormats/');

  const [errors, setErrors] = useState([]);
  const [openPrintRecordModal, setOpenPrintRecordModal] = useState(false);
  const [printRecords, setPrintRecords] = useState(props.printConfig.printRecords);
  // This is the data that the modal loads with
  const [modalContext, setModalContext] = useState({ data: getDefaultPrintRecord(), isEdit: false });

  const printConfig = props.printConfig;

  // Persist changes to parent state when needed
  useEffect(() => {
    props.setPrintConfig({...printConfig, printRecords: printRecords});
  }, [printRecords]);

  const debouncedUpdatePrintConfig = useMemo(
      () => debounce(updatePrintConfig, 300)
      , []);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedUpdatePrintConfig.cancel();
    }
  }, []);

  function validatePrintConfig () {
    // TODO: implement
    return {errors: [], success: true};
  }

  async function updatePrintConfig(printConfig, key, value) {
    const validationResult = validatePrintConfig({printConfig});

    if (validationResult.errors && validationResult.errors.length > 0) {
      // TODO: display error message
    }
    else { // Passed validation, send request

      const response = await axios({

        method: 'patch',
        url: '/api/printConfig/' + printConfig.id,
        data: {fullRecord: printConfig, key, value}

      });

      console.log(response);
      if (response.status === 200) {
        // Update state
        // props.setPrintConfig({...response.data.data, printRecords: printRecords});
      }
      // TODO: on error, rollback changes
    }
  }

  async function deletePrintConfig(printConfigId) {
    const response = await axios({

      method: 'delete',
      url: '/api/printConfig/' + printConfigId,

    });

    console.log(response);
    if (response.status === 200) {
      // Update state
      props.setPrintConfig(printConfig, true);
    }
  }

  return (
      <div className={`px-4 py-5 ${props.isLoading ? "bg-gray-50" : "bg-white"}`}>
        <h3 className={"text-lg font-medium leading-6 text-gray-900"}>Print Configuration</h3>
        <Form
            // buttonText='Create'
            // url={'/api/dataSpec' + (searchParams.get('parent') ? '/' + searchParams.get('parent') : '')}
            // method='POST'
            updateOnChange={true}
            onChange={(e) => {
              if (e.value !== printConfig[e.input]) {
                let updatedPc = {
                  ...printConfig,
                  [e.input]: e.value
                };
                props.setPrintConfig({...updatedPc, printRecords: printRecords});

                if (props.enableAutoSave) {
                  debouncedUpdatePrintConfig(updatedPc, e.input, e.value)
                }
              }
            }}
            className={"pt-1 -ml-5 flex sm:rounded-lg"}
            flex={true}
            data={{
              instructions: {
                label: 'Special Instructions',
                type: 'textarea',
                required: false,
                readonly: props.isFrozen,
                value: printConfig.instructions,
                errorMessage: 'Please enter instructions',
              },
            }}>
        </Form>
        <h4 className={"text-lg font-medium leading-6 text-gray-900 mt-6 mb-2"}>Print Records</h4>
        {printRecords && printRecords.length > 0 ?
            <PrintRecordList className={"ml-3"}
                             records={printRecords}
                             printTypes={printTypes}
                             uidFormats={uidFormats}
                             editRecord={(recordContext) => {
                               if (!props.isFrozen) {
                                 setModalContext({
                                   data: recordContext,
                                   isEdit: true,
                                 });
                                 setOpenPrintRecordModal(true);
                               }
                             }}
            /> : <p className={"ml-3"}>No Printing Records.</p>}
        <span className="hidden sm:block ml-3 mt-3">
        {!props.isFrozen && <button
            type="button"
            onClick={() => {
              setModalContext({
                data: getDefaultPrintRecord(),
                isEdit: false,
              });
              setOpenPrintRecordModal(true);
            }}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
        >
          {/*<PlusCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />*/}
          <Icon image={ 'plus' } className={ Style.labelIcon }/>
          Add Record
        </button>}
      </span>
        <div>
          {!props.isFrozen && <button
              type="button"
              className="inline-flex items-center mt-8 bg-white rounded-md text-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
              onClick={async () => {

                if (props.enableAutoSave) {
                  const e = await deletePrintConfig(props.printConfig.id);
                  if (!e) {
                    setErrors([]);
                  } else {
                    setErrors(e);
                  }
                } else {
                  props.setPrintConfig(props.printConfig, true);
                }
              }}
          >
            <Icon image={'trash'} size={18} className={Style.labelIcon}/>
            Delete Print Configuration
          </button>}
        </div>
        <PrintRecordModal key={modalContext.id || 'newPrintRecordModal'}
                          open={openPrintRecordModal}
                          setOpen={setOpenPrintRecordModal}
                          context={modalContext}
                          records={printRecords}
                          setRecords={setPrintRecords}
                          enableAutoSave={props.enableAutoSave}
                          printTypes={printTypes}
                          dataSources={props.dataSources}
        />
      </div>
  );
}

function PrintRecordList(props) {
  return (<div className={"my-4 " + props.className}>
        <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200">
          {props.records.map((record, index) => (
              <li key={record.id || record.index}>
                <a className="group block cursor-pointer" onClick={() => {
                  props.editRecord(record);
                }}>
                  <div className="flex items-center py-2 px-2">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="shrink-0">
                        <div className="h-12 w-14 bg-orange-50 text-orange-400 text-2xl text-center rounded-md group-hover:opacity-75 flex items-center justify-center">
                          PR{record.index}
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4">
                          <div>
                            <p className="text-sm font-medium text-gray-700">
                              <span className="text-md text-orange-600 truncate">{(!props.printTypes.loading && props.printTypes.data) ? props.printTypes.data.find(x => x.id === parseInt(record.print_type_id)).name : ''}</span>
                              <span className="ml-2 truncate">{record.instructions}</span>
                              {(record.uid_format_id && props.uidFormats.data) && <span className="ml-2 truncate">(UID Format: {props.uidFormats.data.find(x => x.id === parseInt(record.uid_format_id)).name})</span>}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              {record.payload_template && <span className="truncate"><strong>Template:</strong> {record.payload_template}</span>}
                              {record.example_output && <span className="truncate">{record.payload_template && <span>, </span>}<strong>Sample:</strong> {record.example_output}</span>}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Icon image={'chevron-right'}
                            className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                            aria-hidden="true"
                      />
                    </div>
                  </div>
                </a>
              </li>
          ))}
        </ul>
        <div className={"border-t"} />
      </div>
  );
}

function PrintRecordModal(props) {

  const uidFormats = useAPI('/api/uidFormats/');
  const cancelButtonRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const printRecord = props.context.data;
  const [hideUidFormat, setHideUidFormat] = useState(printRecord.use_chip_uid);

  async function addRecord(newRecord) {

    // add correct index (add to end)
    newRecord.index = props.records.length + 1;

    // Validate new record
    if (!newRecord) {
      return ['Record data was empty'];
    }
    if (!newRecord.print_type_id) {
      return ['Print type was missing'];
    }

    if (props.enableAutoSave) {
      // send create request to server

      const response = await axios({

        method: 'post',
        url: '/api/printRecord/',
        data: {data: newRecord},

      });

      console.log(response);
      if (response.status === 200) {
        // Update state
        props.setRecords([...props.records, response.data.data]);
      }
      else if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.statusText,
          message: 'Error when creating record.'
        }]
      }
    }
    else {
      props.setRecords([...props.records, newRecord]);
    }

    return null; // no errors
  }

  async function updateRecord(record) {
    // Validate new record
    if (!record) {
      return ['Record data was empty'];
    }
    if (!record.print_type_id) {
      return ['Print type was missing'];
    }

    if (props.enableAutoSave) {
      // send create request to server

      const response = await axios({

        method: 'patch',
        url: '/api/printRecord/' + record.id,
        data: {data: record},

      });

      console.log(response);
      if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.statusText,
          message: 'Error when updating record.'
        }]
      } else {
        props.setRecords(prev => {
          let temp = [...prev];
          temp.splice(response.data.data.index - 1, 1, response.data.data);
          return temp;
        });
      }
    }
    return null; // no errors
  }

  async function deleteRecord(record) {
    if (props.enableAutoSave) {
      // send delete request to server

      const response = await axios({

        method: 'delete',
        url: '/api/printRecord/' + record.id,

      });

      console.log(response);
      if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.statusText,
          message: 'Error when updating record.'
        }]
      }

      // If successful, update state to include modified records returned by server (indexes will have been remapped)
      props.setRecords(response.data.data);
    }
    else { // not actually in server yet, just delete from state
      // First, remove the record to delete
      let modifiedRecords = props.records.filter(r => r.index !== record.index);

      // Then update index fields to not have gap
      modifiedRecords = modifiedRecords.map((mr, idx) => {
        return {
          ...mr,
          index: idx + 1
        }
      })

      // Update state
      props.setRecords(modifiedRecords);
    }
    return null; // no errors
  }
  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 mt-2 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => null}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-4">
                <div>
                  <div className="">
                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                      {props.context.isEdit ? "Edit" : "Add"} Print Record
                    </Dialog.Title>
                    {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}
                    <div className="mt-2">
                      <Form
                          updateOnChange={true}
                          onChange={(e) => {
                            if (e.input === "use_chip_uid") {
                              setHideUidFormat(!e.value);
                            }
                            printRecord[e.input] = e.value;
                          }}
                          className={"p-1 bg-white space-y-5 sm:rounded-lg"}
                          flex={true}
                          data={{
                            print_type_id: {
                              label: 'Print Type',
                              type: 'select',
                              options: (!props.printTypes.loading && props.printTypes.data) ? props.printTypes.data.map(x => {return {value: x.id, label: x.name}}) : [],
                              required: true,
                              value: printRecord.print_type_id,
                              default: printRecord.print_type_id,
                              errorMessage: 'Please select a print type',
                            },
                            instructions: {
                              label: 'Record Instructions',
                              type: 'textarea',
                              required: false,
                              value: printRecord.instructions,
                              errorMessage: 'Please enter instructions',
                            },
                            use_chip_uid: {
                              label: 'Use Chip UID',
                              type: 'singleCheckbox',
                              required: false,
                              value: printRecord.use_chip_uid,
                              errorMessage: '',
                              tooltip: 'Reference in template as {{chip_uid}}',
                            },
                            uid_format_id: {
                              label: 'UID Format',
                              type: 'select',
                              options: (!uidFormats.loading && uidFormats.data) ? uidFormats.data.map(x => {return {value: x.id, label: x.name}}) : [],
                              required: false,
                              isHidden: hideUidFormat, // TODO: this state doesn't trigger update
                              value: printRecord.uid_format_id || "",
                              default: printRecord.uid_format_id,
                              errorMessage: 'Please select a UID format type',
                            },
                            data_sources: {
                              label: 'Data Sources',
                              type: 'customMultiSelect',
                              options: props.dataSources ? props.dataSources.map(x => {return {value: x.id, label: `${x.name} {{${x.name.toLowerCase().trim().replaceAll(' ', '_')}}}`, secondaryText: x.sample_value, orig: x}}) : [],
                              required: false,
                              value: printRecord.data_sources ? printRecord.data_sources : [],
                              default:  printRecord.data_sources ? printRecord.data_sources : [],
                              errorMessage: 'Please select data sources',
                            },
                            payload_template: {
                              label: 'Payload Template',
                              type: 'text',
                              required: false,
                              value: printRecord.payload_template,
                              errorMessage: 'Please enter payload template',
                              tooltip: 'Reference data sources via the {{data_source_name}} format.',
                            },
                            example_output: {
                              label: 'Sample Output',
                              type: 'text',
                              required: false,
                              value: printRecord.example_output,
                              errorMessage: 'Please enter a sample output',
                            },
                          }}>
                      </Form>
                    </div>
                  </div>
                </div>
                {props.context.isEdit &&
                    <div>
                      <button
                          type="button"
                          className="inline-flex items-center mt-4 ml-8 bg-white rounded-md text-md font-medium text-red-600 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200"
                          onClick={async () => {
                            const e = await deleteRecord(printRecord);
                            if (!e) {
                              props.setOpen(false);
                              setErrors([]);
                            }
                            else {
                              setErrors(e);
                            }
                          }}
                      >
                        <Icon image={ 'trash' } size={18} className={ Style.labelIcon }/>
                        Delete Record
                      </button>
                    </div>
                }
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={async () => {
                        const e = await props.context.isEdit ? await updateRecord(printRecord) : await addRecord(printRecord);
                        if (!e) {
                          props.setOpen(false);
                          setErrors([]);
                        }
                        else {
                          setErrors(e);
                        }
                      }}
                  >
                    {props.context.isEdit ? "Update" : "Create"} Record
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                        setErrors([]);
                      }}
                      ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
