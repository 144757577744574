import {Icon} from 'components/lib';

export function ErrorMessage(props) {
  return (
    <div className={"rounded-md bg-red-100 p-4 " + props.className}>
      <div className="flex justify-between items-center">
        <div className="flex">
          <div className="flex-shrink-0">
            <Icon image={ 'alert-circle' } className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              {props.errors.length > 1 ? `There were ${props.errors.length} errors with your submission`
                : `There was an error with your submission`
              }
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc pl-5 space-y-1">
                {props.errors.map((error, index) => (
                  <li key={error.substring(0,5) + '-' + index}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="text-xs font-medium text-red-800 cursor-pointer underline" onClick={() => props.dismissOnClick()}>dismiss</div>
      </div>
    </div>
  )
}
