import { Home } from 'views/website/home';
import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';
import {Download} from "../views/website/downlaod";

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: 'Datastand by Tagstand'
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing'
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions'
  },

  {
    path: '/download',
    view: Download,
    layout: 'home',
    title: 'Datastand by Tagstand'
  },
]

export default Routes;
