import React, {Fragment, useEffect, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import {DataSpecPdf} from "./DataSpecPdf";

export function PreviewPdf(props) {

  const cancelButtonRef = useRef(null);

  // We need to resize the PDF viewer based on window size
  //  Source: https://stackoverflow.com/questions/36862334/get-viewport-window-height-in-reactjs
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0 md:ml-[76px]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle md:max-w-2xl lg:max-w-3xl xl:max-w-5xl sm:w-full sm:p-6">
              <div>
                <div className="">
                  <Dialog.Title as="h3" className="text-lg text-center leading-6 font-semibold text-gray-900">
                    Preview PDF Export
                  </Dialog.Title>
                  <PDFViewer className="mt-6 mx-auto" height={windowDimensions.height * .7} width={windowDimensions.width > 1280 ? "900px" :
                    windowDimensions.width > 1024 ? "740px" : windowDimensions.width > 768 ? "600px" : Math.max(windowDimensions.width * .6, 300)}>
                    <DataSpecPdf dataSpec={props.dataSpec} customer={props.customer} printConfig={props.printConfig} chipConfigs={props.chipConfigs} assignedTo={props.assignedTo} dataListColumns={props.dataListColumns} lastSaveTime={props.lastSaveTime} factoryInstructions={props.factoryInstructions} />
                  </PDFViewer>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <PDFDownloadLink
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                  document={<DataSpecPdf dataSpec={props.dataSpec} customer={props.customer} printConfig={props.printConfig} chipConfigs={props.chipConfigs} assignedTo={props.assignedTo} dataListColumns={props.dataListColumns} lastSaveTime={props.lastSaveTime} factoryInstructions={props.factoryInstructions} />}
                  fileName={props.dataSpec.number + '_export.pdf'}>
                  Download PDF
                </PDFDownloadLink>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
