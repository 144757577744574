import React from 'react';
import { Page, Text, View, Document, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { useAPI } from 'components/lib';
import {hasChipVariableData, hasSharedDataSource, hasPrintingVariableData, isChipUidReferenced, hasChipDataLocking, hasChipCollectUID} from '../shared/util';
import Logo from './images/logo.png';
import SSP_Black from "./fonts/SourceSansPro-Black.ttf";
import SSP_Bold from "./fonts/SourceSansPro-Bold.ttf";
import SSP_SemiBold from "./fonts/SourceSansPro-SemiBold.ttf";
import SSP_Regular from "./fonts/SourceSansPro-Regular.ttf";
import SSP_Light from "./fonts/SourceSansPro-Light.ttf";
import SSP_ExtraLight from "./fonts/SourceSansPro-ExtraLight.ttf";
import SSP_Italic from "./fonts/SourceSansPro-Italic.ttf";


Font.register({ family: 'Source Sans Pro', fonts: [
    { src: SSP_Regular }, // font-style: normal, font-weight: normal
    { src: SSP_Regular, fontWeight: 400 }, // font-style: normal, font-weight: normal
    { src: SSP_Light, fontWeight: 'light' },
    { src: SSP_Light, fontWeight: 300 },
    { src: SSP_ExtraLight, fontWeight: 'ultralight' },
    { src: SSP_ExtraLight, fontWeight: 200 },
    { src: SSP_SemiBold, fontWeight: 'semibold' },
    { src: SSP_SemiBold, fontWeight: 600 },
    { src: SSP_Bold, fontWeight: 'bold' },
    { src: SSP_Bold, fontWeight: 700 },
    { src: SSP_Black, fontWeight: 'heavy' },
    { src: SSP_Black, fontWeight: 900 },
    { src: SSP_Italic, fontStyle: 'italic' },
  ]});

// Create styles
const styles = StyleSheet.create({
  page: {
    color: '#3A3536',
    // fontFamily: 'Source Sans Pro',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#ffffff',
    minHeight: '100vh',
    padding:"10px",
    paddingBottom:"30px"
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  section: {
    marginTop: 4,
    marginLeft: 16,
    marginRight: 16,
    padding: 4,
    paddingRight: 0,
    border: '1px solid #ffd4b0',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  sectionTop: {
    marginTop: 16,
    marginBottom: 6,
    padding: 4,
    paddingRight: 0,
  },
  keyAttributeParent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 4,
  },
  keyAttributeYes: {
    margin: 2,
    padding: 2,
    paddingRight: 0,
    border: '1px solid #21C55E',
    color: '#21C55E',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    display: 'flex',
    flexDirection: 'col',
    alignItems: 'center',
  },
  keyAttributeNo: {
    backgroundColor: '#F9FAFB',
    margin: 2,
    padding: 2,
    paddingRight: 0,
    border: '1px solid #EF4444',
    color: '#EF4444',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    display: 'flex',
    flexDirection: 'col',
    alignItems: 'center',
    fontWeight: 600,
  },
  keyAttributeTitle: {
    // backgroundColor: '#ffd4b0',
    color: '#333',
    marginTop: -4,
    marginLeft: -4,
    padding: 4,
    fontSize: 14,
    fontWeight: 600,
  },
  logo: {
    width: '112px',
    height: '23px',
  },
  textTitle: {
    backgroundColor: '#ffd4b0',
    color: '#70390b',
    marginTop: -4,
    marginLeft: -4,
    padding: 4,
    fontSize: 14,
    fontWeight: 600,
  },
  textH1: {
    fontSize: 16,
    fontWeight: 700,
    paddingVertical: 4,
    paddingHorizontal: 2,
  },
  textHeader: {
    fontSize: 16,
    paddingVertical: 4,
    paddingHorizontal: 2,
  },
  textHeaderSub: {
    fontSize: 16,
    fontWeight: 600,
    paddingVertical: 4,
    paddingHorizontal: 2,
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    paddingVertical: 4,
    paddingHorizontal: 2,
  },
  recordId: {
    height: 24,
    width: 28,
    backgroundColor: '#FFF6ED',
    color: '#FB923C',
    fontSize: 12,
    fontWeight: 700,
    marginTop: 2,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #FB923C',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  recordRow: {
    padding: 2,
    marginVertical: 1,
    marginRight: 16,
    marginLeft: 6,
    border: '1px solid #b2b2b2',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  chipSection: {
    margin: 4,
    padding: 4,
    paddingRight: 0,
    backgroundColor: '#F9FAFB',
    border: '1px solid #b2b2b2',
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 11,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

// Create Document Component
export function DataSpecPdf(props) {
  const printTypes = useAPI('/api/printTypes/');
  const transponderModels = useAPI('/api/transponderModels/');
  const dataRecordTypes = useAPI('/api/dataRecordTypes/');
  const uidFormats = useAPI('/api/uidFormats/');
  const dataListSources = useAPI('/api/dataListSources/');

  console.log(props.chipConfigs.map(cc => cc.id));
  return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page} wrap={true}>
          <View style={styles.sectionTop}>
            <View style={styles.header}>
              <View style={{flexDirection:'column'}}>
                <Text style={[styles.textHeader, {}]} >Customer: <Text style={{fontWeight:700}}>{props.customer.name}</Text></Text>
                <Text style={[styles.textHeader, {}]} >Number #: <Text style={{fontWeight:700}}>{props.dataSpec.number}</Text></Text>
              </View>
              <View style={{flexDirection:'column'}}>
                <Image
                    style={styles.logo}
                    src={Logo}
                />
                <Text style={styles.textHeaderSub}>Data Specification</Text>
              </View>
              <View style={{flexDirection:'column'}}>
                {props.lastSaveTime && <Text style={[styles.textHeaderSub, {fontWeight:400}]} ><Text style={{fontWeight:700}}>Last Updated:</Text> {props.lastSaveTime.toLocaleString()}</Text>}
                {props.assignedTo && <Text style={[styles.textHeaderSub, {fontWeight:400}]} ><Text style={{fontWeight:700}}>Assigned To:</Text> {props.assignedTo.name}</Text>}
              </View>
            </View>
          </View>
          <View style={styles.section} wrap={true}>
            <Text style={styles.textTitle}>Details</Text>
            {props.dataSpec.description &&
                <Text style={styles.text}><Text style={{fontWeight: 700}}>Description:</Text> {props.dataSpec.description}
                </Text>}
            {props.dataSpec.data_deliverables_customer &&
                <Text style={styles.text}><Text style={{fontWeight: 700}}>Data Deliverables to
                  Customer:</Text> {props.dataSpec.data_deliverables_customer}</Text>}
            <View style={styles.keyAttributeParent }>
              <View style={hasSharedDataSource(props.printConfig, props.chipConfigs) ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Match Printing and Encoding</Text>
                {hasSharedDataSource(props.printConfig, props.chipConfigs) ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
              <View style={hasPrintingVariableData(props.printConfig) ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Printing Variable Data</Text>
                {hasPrintingVariableData(props.printConfig) ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
              <View style={hasChipVariableData(props.chipConfigs) ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Encoding Variable Data</Text>
                {hasChipVariableData(props.chipConfigs) ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
              <View style={isChipUidReferenced(props.printConfig, props.chipConfigs) ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Data Uses Chip UID</Text>
                {isChipUidReferenced(props.printConfig, props.chipConfigs) ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
              <View style={hasChipDataLocking(props.chipConfigs) ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Chip Locking</Text>
                {hasChipDataLocking(props.chipConfigs) ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
              <View style={hasChipCollectUID(props.chipConfigs) ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Collect UID</Text>
                {hasChipCollectUID(props.chipConfigs) ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
            </View>
            {props.factoryInstructions && <View style={styles.keyAttributeParent }>
              <View>
                <Text style={{fontSize:14}}>Factory Data</Text>
                <Text style={{fontSize:14}}>Deliverables</Text>
              </View>
              <View>
                <Text style={{fontSize:18, marginRight: 4, marginLeft: 4,}}>:</Text>
              </View>
              <View style={props.factoryInstructions.returnChipUid ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Return Chip UID</Text>
                {props.factoryInstructions.returnChipUid ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
              <View style={props.factoryInstructions.returnUnrealized ? styles.keyAttributeYes : styles.keyAttributeNo }>
                <Text style={styles.keyAttributeTitle}>Return Unrealized Rows</Text>
                {props.factoryInstructions.returnUnrealized ?
                    <Text>YES</Text> :
                    <Text>NO</Text>
                }
              </View>
            </View>}
          </View>
          { (props.dataSpec.use_chip_uid || props.dataSpec.data_list_source_id || (props.dataListColumns && props.dataListColumns.length > 0)) &&
              <View style={styles.section} wrap={true}>
                <Text style={styles.textTitle}>Data List</Text>
                {props.dataSpec.data_list_source_id &&
                    <Text style={styles.text}><Text style={{fontWeight: 700}}>Data List Provided By:</Text> {(!dataListSources.loading && dataListSources.data) ? dataListSources.data.find(x => x.id === parseInt(props.dataSpec.data_list_source_id)).name : ''}
                    </Text>}
                {(props.dataListColumns && props.dataListColumns.length > 0) &&
                    <View>
                      <Text style={styles.text}><Text style={{fontWeight: 700}}>Data List Columns (CSV headers):</Text></Text>
                      { props.dataListColumns.map((col, index) => {
                        return (
                            <View style={[styles.recordRow, {flexDirection:'row'}]} key={col.id}>
                              <View style={styles.text}>
                                <Text style={{fontWeight: 700}}>{index+1}: {col.name}</Text>
                              </View>
                              <View style={{flexDirection:'column', fontSize: 11, marginLeft: 6}}>
                                <View style={{flexDirection:'row'}}>
                                  <Text>
                                    {col.description ? col.description : ''}
                                  </Text>
                                </View>
                                <View style={{flexDirection: 'row', color: 'rgba(0,0,0,0.6)'}}>
                                  {col.sample_value &&
                                      <Text style={{fontSize: 9, paddingRight: 28}}>
                                        <Text style={{fontWeight:700}}>Sample:</Text> {col.sample_value}
                                      </Text>
                                  }
                                </View>
                              </View>
                            </View>
                        );
                      })
                      }
                    </View>
                }
              </View>
          }
          { props.printConfig &&
              <View style={styles.section} wrap={true}>
                <Text style={styles.textTitle}>Print Configuration</Text>
                { props.printConfig.instructions && <Text style={styles.text}><Text style={{fontWeight:700}}>Instructions:</Text> {props.printConfig.instructions}</Text>}
                { props.printConfig.printRecords.map(record => {
                  return (
                      <View style={[styles.recordRow, {flexDirection:'row'}]} key={record.id}>
                        <View style={styles.recordId}>
                          <Text>PR{record.index}</Text>
                        </View>
                        <View style={{flexDirection:'column', fontSize: 11, marginLeft: 6}}>
                          <View style={{flexDirection:'row'}}>
                            <Text>
                              <Text style={{fontWeight:700}}>{(!printTypes.loading && printTypes.data) ? printTypes.data.find(x => x.id === parseInt(record.print_type_id)).name : ''}</Text>
                              {record.instructions ? ' - ' + record.instructions : ''}
                            </Text>
                          </View>
                          {record.payload_template &&
                              <View style={{flexDirection: 'row', color: 'rgba(0,0,0,0.6)'}}>
                                <Text style={{fontSize: 9, paddingRight: 28}}>
                                  <Text style={{fontWeight: 700}}>Template:</Text> {record.payload_template}
                                </Text>
                              </View>
                          }
                          {record.example_output &&
                              <View style={{flexDirection: 'row', color: 'rgba(0,0,0,0.6)'}}>
                                <Text style={{fontSize: 9, paddingRight: 28}}>
                                  <Text style={{fontWeight: 700}}>Sample:</Text> {record.example_output}
                                </Text>
                              </View>}
                        </View>
                      </View>
                  );
                })
                }
              </View>
          }
          { props.chipConfigs && props.chipConfigs.length > 0 &&
              <View style={styles.section} wrap={true}>
                <Text style={styles.textTitle}>Chip Configurations</Text>
                {props.chipConfigs.map(chipConfig => {
                  return (
                      <View key={chipConfig.id} style={[styles.chipSection, {}]}>
                        <Text style={styles.text}><Text style={{fontWeight:700}}>Model: {(!transponderModels.loading && transponderModels.data) ? transponderModels.data.find(x => x.id === parseInt(chipConfig.transponder_model_id)).name : ''}</Text></Text>
                        { chipConfig.instructions && <Text style={styles.text}><Text style={{fontWeight:700}}>Instructions:</Text> {chipConfig.instructions}</Text> }
                        <Text style={styles.text}><Text style={{fontWeight:700}}>Lock All Records:</Text> {chipConfig.lock_all ? 'YES' : 'no'}</Text>
                        <Text style={styles.text}><Text style={{fontWeight:700}}>Collect Chip UID:</Text> {chipConfig.collect_chip_uid ? 'YES' : 'no'}</Text>
                        { chipConfig.password && <Text style={styles.text}><Text style={{fontWeight:700}}>Password:</Text> {chipConfig.password}</Text> }
                        {
                          chipConfig.dataRecords.map(record => {
                            return (
                                <View style={[styles.recordRow, {flexDirection: 'row', backgroundColor: '#fff'}]} key={record.id}>
                                  <View style={styles.recordId}>
                                    <Text>ER{record.index}</Text>
                                  </View>
                                  <View style={{flexDirection: 'column', fontSize: 11, marginLeft: 6}}>
                                    <View style={{flexDirection: 'row'}}>
                                      <Text>
                                        <Text style={{fontWeight:700}}>{(!dataRecordTypes.loading && dataRecordTypes.data) ? dataRecordTypes.data.find(x => x.id === parseInt(record.data_record_type_id)).name : ''}</Text>
                                        {record.instructions ? ' - ' + record.instructions : ''}
                                      </Text>
                                    </View>
                                    {record.payload_template &&
                                        <View style={{flexDirection: 'row', color: 'rgba(0,0,0,0.6)'}}>
                                          <Text style={{fontSize: 9, paddingRight: 28}}>
                                            <Text style={{fontWeight: 700}}>Template:</Text> {record.payload_template}
                                          </Text>
                                        </View>
                                    }
                                    {record.example_output &&
                                        <View style={{flexDirection: 'row', color: 'rgba(0,0,0,0.6)'}}>
                                          <Text style={{fontSize: 9, paddingRight: 28}}>
                                            <Text style={{fontWeight: 700}}>Sample:</Text> {record.example_output}
                                          </Text>
                                        </View>}
                                    {record.uid_format_id &&
                                        <View style={{flexDirection: 'row', color: 'rgba(0,0,0,0.6)'}}>
                                          <Text style={{fontSize: 9, paddingRight: 28}}>
                                            <Text style={{fontWeight:700}}>UID Format:</Text> {(!uidFormats.loading && uidFormats.data) ? uidFormats.data.find(x => x.id === parseInt(record.uid_format_id)).name : ''}
                                          </Text>
                                        </View>}
                                  </View>
                                </View>
                            );
                          })
                        }
                      </View>);
                })
                }
              </View>
          }
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
              `Page ${pageNumber} of ${totalPages}`
          )} fixed />
        </Page>
      </Document>);
}
