/***
 *
 *   ORDERS
 *   View customers/orgs
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  AppLayoutContext,
  DateCell,
  CustomerCell,
  Icon,
  Loader,
  ReactTable,
  TextInput,
  useAPI
} from 'components/lib';
import axios from "axios";

export function Customers(props){
  const customers = useAPI('/api/customer/');
  console.log(customers);

  const [customerIdInput, setCustomerIdInput] = useState('');
  let [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Name",
      accessor: 'name',
      Cell: CustomerCell,
    },
    {
      Header: "Vtiger Id",
      accessor: 'vtiger_id',
    },
    {
      Header: "Account No.",
      accessor: 'account_no',
    },
    {
      Header: "Vtiger Assigned To (Id)",
      accessor: 'vtiger_assigned_to_id',
    },
    {
      Header: "Vtiger Assigned To",
      accessor: 'vtiger_assigned_to_name',
    },
    {
      Header: "Vtiger Assigned To (email)",
      accessor: 'vtiger_assigned_to_email',
    },
    {
      Header: "Original Sync Date",
      accessor: 'created_at',
      Cell: DateCell,
    },
    {
      Header: "Last Sync Date",
      accessor: 'updated_at',
      Cell: DateCell,
    },
  ], [customers]);

  async function syncCustomer(customerId) {

    if (!customerId) {
      return "Customer Id is required.";
    }
    else { // Passed validation, send request
      const response = await axios({

        method: 'get',
        url: '/api/customer/sync/' + customerId,

      });

      console.log(response); // TODO: remove after testing
      if (response.status === 200) {
        window.location.reload();
      }
      // TODO: on error, rollback changes
    }
  }

  return (
    <Fragment>

      { customers.loading || customers.data == null ?
        <Loader /> :
        <>
          <ReactTable columns={columns} data={customers.data} limit_search={true}/>
          <div className={"text-lg font-semibold leading-6 mb-1 text-gray-900"}>Sync Customer</div>
          <div className="flex mb-3 text-gray-400 items-center">
            <p className="text-sm text-gray-400">
              Enter either Vtiger Org. Id (ex: '3x12345') or Datastand Customer Id (ex: 'abc1def2-3ghi-...')
            </p>
          </div>
          { !isLoading && <div className={"flex mt-2 mb-6"}>
            <TextInput placeholder={"Customer Id"} value={customerIdInput} onChange={(name, value) => setCustomerIdInput(value)} outerClassName={"mr-2"} />
            <button
              type="button"
              onClick={async () => {
                console.log(customerIdInput, 'sync req.');
                setIsLoading(true);
                const e = await syncCustomer(customerIdInput);
                if (!e) {
                  setCustomerIdInput('');
                }
                else {
                  setErrors([e]);
                }
              }}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
            >
              <Icon image={'refresh-cw'} className="mr-2" />
              Sync Customer
            </button>
          </div>}
          { isLoading && <span className={"text-gray-600 text-md loading-trailing-dots"} ><Loader classNames={`w-6 h-6 inline-block mr-2`} />Syncing Customer...</span>}
        </>
      }
    </Fragment>
  )
}
