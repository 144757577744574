
import React from 'react';
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
// import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Icon, Label, Error, ClassHelper } from 'components/lib';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function removeDuplicates(arr) {
  const newArr = [];
  arr.forEach(e => {
    if (!newArr.find(x => x.value === e.value)) {
      newArr.push(e);
    }
  })
  return newArr;
}

export function CustomMultiSelect(props){

  const [selectedOptions, setSelectedOptions] =
    useState(props.value && props.value.length > 0?
      props.value.map(v => {
        return {
          value: v.id,
          label: props.options.find(o => o.value === v.id).label,
          secondaryText: props.options.find(o => o.value === v.id).secondaryText,
          orig: v,
        };
      }) :
      []);

  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  // set the default
  if (!props.default && options?.length){

    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: 'Please select an option' });

  }

  function change(selection){

    let value = selection && selection.length > 0 ? selection.filter(s => s.value !== 'unselected').map(s => {
      return s.orig;
    }) : [];
    let valid = undefined;

    // validate
    valid = props.required && value === [] ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value)

  }

  return(
    <div className={props.outerClassName}>
      <Listbox multiple value={selectedOptions} onChange={(selection) => {
        console.log(removeDuplicates(selection));
        change(removeDuplicates(selection));
        setSelectedOptions(removeDuplicates(selection));
      }}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700 uppercase">{props.label}</Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">

                <span className="block flex flex-wrap gap-2">
                    {selectedOptions.length === 0 ? (
                      <span className="p-0.5">None selected</span>
                    ) : (
                      selectedOptions.map((s) => (
                        <span
                          key={s.value}
                          className="flex items-center gap-1 rounded bg-orange-500 text-white px-2 py-0.5"
                        >
                          <span>{s.label}</span>
                          <svg
                            className="h-4 w-4 cursor-pointer"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={(e) => {
                              e.stopPropagation()
                              e.preventDefault()
                              change(selectedOptions.filter((p) => p !== s));
                              setSelectedOptions((existing) => {
                                return existing.filter((p) => p !== s);
                              });
                            }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </span>
                      ))
                    )}
                  </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-[999] mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-orange-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      disabled={!option.value || option.value === 'unselected'}
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex">
                            <span className={classNames(selectedOptions.find(s => s.value === option.value) || selected  ? 'font-semibold' : 'font-normal', '')}>
                              {option.label}
                            </span>
                            <span className={classNames(active ? 'text-orange-200' : 'text-gray-500', 'ml-2 truncate')}>
                              {option.secondaryText}
                            </span>
                          </div>

                          {selectedOptions.find(s => s.value === option.value) || selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-orange-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <Icon image="check" className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
