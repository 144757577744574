import React, {Fragment, useContext, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AuthContext, ErrorMessage, Form, useAPI} from 'components/lib';
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export function CreateDataSpecModal(props) {
  const context = useContext(AuthContext);

  const customers = useAPI('/api/customer/');
  let dataSpecs = useAPI('/api/dataSpec');

  const cancelButtonRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const [linkOrCreateToggle, setLinkOrCreateToggle] = useState(props.allowExistingLink);
  const [existingDataSpecId, setExistingDataSpecId] = useState(undefined);

  let is_duplicate= props.is_duplicate;
  if (is_duplicate===undefined){
    is_duplicate=false;
  }

  const [duplicateOrCreateToggle, setDuplicateOrCreateToggle] = useState(false);

  let [dataSpec, setDataSpec] = useState({
    number: '',
    customer_id: props.customerId || '',
  });


  async function createDataSpec(newDataSpec) {

    // Validate new record
    if (!newDataSpec) {
      return ['Data was empty'];
    }
    const errors = [];
    // if (!newDataSpec.number) {
    //   errors.push('DS Number was missing');
    // }
    if (!newDataSpec.customer_id) {
      errors.push('Organization was missing');
    }
    if (errors.length > 0) {
      return errors;
    }


    // send create request to server
    const response = await axios({

      method: 'post',
      url: props.orderItemId ? '/api/dataspec/' + props.orderItemId : (is_duplicate ? '/api/dataspec/'+dataSpec.id : '/api/dataspec/'),
      data: {
        dataSpec: {
          ...newDataSpec,
          created_by_id: context.user?.user_id,
        },
        otherData:{
          duplicate_id: is_duplicate ? true : duplicateOrCreateToggle,
          existingDataSpecId: is_duplicate ? dataSpec.id : existingDataSpecId,
        }
      },

    });

    console.log(response);
    if (response.status === 200) {
      // Update state
      props.afterCreate(response.data.data);
    }
    else if (response.status !== 200) {
      // return error if not '200 OK'
      return [{
        errorStatus: response.status,
        errorStatusText: response.statusText,
        message: 'Error when creating data spec.'
      }]
    }
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="">
                  <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                    {props.allowExistingLink ? 'Link or ' : ''}Create Data Specification
                  </Dialog.Title>
                  {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}
                  {props.allowExistingLink &&
                  <div className="relative z-0 w-70 mt-6 mx-auto rounded-l-md flex shadow-sm rounded-md">
                    <button type="button"
                            className={classNames(
                                linkOrCreateToggle ? 'bg-gray-200':'bg-white',
                                "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500")}
                        onClick={() => {
                          setDuplicateOrCreateToggle(true);
                          setLinkOrCreateToggle(true);
                        }}
                    >
                      <span className="">Duplicate Existing</span>
                    </button>
                    <button type="button"
                            className={classNames(
                              linkOrCreateToggle ? 'bg-white':'bg-gray-200',
                              "relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500")}
                              onClick={() => {
                                setLinkOrCreateToggle(true);
                                setDuplicateOrCreateToggle(false);
                              }
                    }
                    >
                      <span className="">Link Existing</span>
                    </button>
                    <button type="button"
                              className={classNames(
                              linkOrCreateToggle ? 'bg-gray-200':'bg-white',
                              "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500")}
                              onClick={() => {
                                setLinkOrCreateToggle(false);
                                setDuplicateOrCreateToggle(false);
                              }}
                    >
                      <span className="">Create New</span>
                    </button>


                  </div>
                  }
                  {is_duplicate &&
                      <div className="mt-2">
                        <Form
                            updateOnChange={true}
                            onChange={(e) => {
                              setDataSpec({
                                ...dataSpec,
                                [e.input]: e.value
                              })
                            }}
                            className={"p-1 bg-white space-y-6 sm:rounded-lg"}
                            flex={true}
                            data={{
                              customer_id: {
                                label: 'Organization',
                                type: 'select',
                                options: (!customers.loading && customers.data) ? customers.data.map(x => {return {value: x.id, label: x.name}}) : [],
                                required: true,
                                value: dataSpec.customer_id,
                                default: dataSpec.customer_id,
                                errorMessage: 'Please select an organization',
                              },
                              dataSpecId: {
                                label: 'DataSpec',
                                // type: 'customSelect',
                                type: 'select',
                                options: (!dataSpecs.loading && dataSpecs.data) ?
                                    dataSpecs.data.filter(x => x.customer_id === (props.customerId ===undefined ? dataSpec.customer_id : props.customerId))
                                        .map(x => {return {value: x.id, label: x.number + (x.description ?  x.description.length > 60 ? ' - ' + x.description.slice(0,60) + '...' : ' - ' + x.description : '')}}) : [],
                                required: true,
                                value: existingDataSpecId,
                                default: existingDataSpecId,
                                errorMessage: 'Please select a Data Spec',
                              },
                              duplicate_id:{
                                label:'Duplicate',
                                type:'hidden',
                                value: true,
                              }

                              // number: {
                              //   label: 'Data Specification Number',
                              //   type: 'text',
                              //   required: true,
                              //   value: dataSpec.number,
                              //   errorMessage: 'Please enter a DS number',
                              // },
                            }}>
                        </Form>
                      </div>
                  }

                  {linkOrCreateToggle &&
                    <div className="mt-2">
                    <Form
                      updateOnChange={true}
                      onChange={(e) => {
                        setExistingDataSpecId(e.value)
                      }}
                      className={"p-1 bg-white space-y-6 sm:rounded-lg"}
                      flex={true}
                      data={{
                        dataSpecId: {
                          label: 'DataSpec',
                          // type: 'customSelect',
                          type: 'select',
                          options: (!dataSpecs.loading && dataSpecs.data) ?
                            dataSpecs.data.filter(x => x.customer_id === props.customerId)
                              .map(x => {return {value: x.id, label: x.number + (x.description ?  x.description.length > 60 ? ' - ' + x.description.slice(0,60) + '...' : ' - ' + x.description : '')}}) : [],
                          required: true,
                          value: existingDataSpecId,
                          default: existingDataSpecId,
                          errorMessage: 'Please select a Data Spec',
                        },
                        duplicate_id:{
                          label:'Duplicate',
                          type:'hidden',
                          value: duplicateOrCreateToggle,
                        }
                      }}>
                    </Form>
                  </div>

                  }
                  {!linkOrCreateToggle && !is_duplicate &&
                  <div className="mt-2">
                    <Form
                      updateOnChange={true}
                      onChange={(e) => {
                        setDataSpec({
                          ...dataSpec,
                          [e.input]: e.value
                        })
                      }}
                      className={"p-1 bg-white space-y-6 sm:rounded-lg"}
                      flex={true}
                      data={{
                        customer_id: {
                          label: 'Organization',
                          type: 'select',
                          options: (!customers.loading && customers.data) ? customers.data.map(x => {return {value: x.id, label: x.name}}) : [],
                          required: true,
                          value: dataSpec.customer_id,
                          default: props.customerId,
                          errorMessage: 'Please select an organization',
                        },
                        duplicate_id:{
                          label:'Duplicate',
                          type:'hidden',
                          value: duplicateOrCreateToggle,
                        }

                        // number: {
                        //   label: 'Data Specification Number',
                        //   type: 'text',
                        //   required: true,
                        //   value: dataSpec.number,
                        //   errorMessage: 'Please enter a DS number',
                        // },
                      }}>
                    </Form>
                  </div>}
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                  onClick={async () => {
                    if (props.allowExistingLink && linkOrCreateToggle) {
                      if (existingDataSpecId) {
                        if(duplicateOrCreateToggle) {
                          const e = await createDataSpec(dataSpec);
                        }else{
                          props.linkCallback(existingDataSpecId);
                        }

                      }
                      // TODO: show error message if data spec missing?
                    }
                    else {
                      const e = await createDataSpec(dataSpec);
                      if (!e) {
                        props.setOpen(false);
                        setErrors([]);
                      } else {
                        setErrors(e);
                      }
                    }
                  }}
                >
                  {linkOrCreateToggle || is_duplicate ? ((!duplicateOrCreateToggle && !is_duplicate) ? 'Link Data Specification' :'Duplicate Data Specification') : 'Create Data Specification'}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    props.setOpen(false);
                    setErrors([]);
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
