/***
 *
 *   VIEW ORDER
 *   View a sales order
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  AppLayoutContext,
  DataSpecNumberCell,
  OrderNumberCell,
  DateCell,
  HyperlinkCell,
  Icon,
  Loader,
  ReactTable,
  useAPI
} from 'components/lib';
import axios from "axios";

async function syncCustomer(customerId) {

  if (!customerId) {
    return "Customer Id is required.";
  }
  else { // Passed validation, send request
    const response = await axios({

      method: 'get',
      url: '/api/customer/sync/' + customerId,

    });

    console.log(response); // TODO: remove after testing
    if (response.status === 200) {
      window.location.reload();
    }
    // TODO: on error, rollback changes
  }
}

export function ViewCustomer(props){

  let navigate = useNavigate();
  const { customerId } = useParams();
  const customerFromApi = useAPI('/api/customer/' + customerId);
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');
  const [customerData, setCustomerData] = useState(customerFromApi.data);
  let [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  useEffect(() => {
    async function asyncFunction() {
      console.log(customerFromApi)
      if (customerFromApi.data) {
        setCustomerData(customerFromApi.data);
      }
    }
    asyncFunction();
  }, [customerFromApi]);

  const dataSpecColumns = React.useMemo(() => [
    {
      Header: "DS Number",
      accessor: 'number',
      Cell: DataSpecNumberCell,
    },
    {
      Header: "Description",
      accessor: 'description',
    },
    {
      Header: "Assigned To",
      accessor: (row) => {
        return row && row.assigned_to_id && users.data ? users.data.find(u => u.id === row.assigned_to_id).name: row.assigned_to_id
      },
    },
    {
      Header: "Created",
      accessor: 'created_at',
      Cell: DateCell,
    },
    {
      Header: "Created By",
      accessor: (row) => {
        return row && row.created_by_id && users.data ? users.data.find(u => u.id === row.created_by_id).name: row.created_by_id
      },
    },
  ], [users])

  const orderColumns = React.useMemo(() => [
    {
      Header: "Order Number",
      accessor: 'vtiger_salesorder_no',
      Cell: OrderNumberCell,
    },
    {
      Header: "Status",
      accessor: 'vtiger_sostatus',
      // Cell: StatusPill,
    },
    {
      Header: "vTiger Created Time",
      accessor: 'vtiger_createdtime',
      Cell: DateCell,
    },
    {
      Header: "vTiger Modified Time",
      accessor: 'vtiger_modifiedtime',
      Cell: DateCell,
    },
    {
      Header: "Last Sync",
      accessor: 'updated_at',
      Cell: DateCell,
    },
    {
      Header: "vTiger Link",
      accessor: 'external_link',
      Cell: HyperlinkCell,
    },
  ], [])

  return (
    <Fragment>

      { customerData == null || customers.loading ?
        <Loader /> :
        <>
          <div className={"flex mb-4 -mt-2"}>
            <a className={"relative text-sm hover:underline text-orange-500"}
               href={`/customers`}>
              Customers
            </a>
            <span className={"mx-2 text-sm text-gray-400"}> / </span>
            <p className={"relative text-sm text-gray-500"}>
              {customers.data ? customers.data.find(u => u.id === customerId).name: customerId}
            </p>
          </div>
          <div className={"p-4 bg-white m-4 shadow sm:rounded-lg"}>
            <h3 className={"text-xl font-semibold mb-4 leading-6 text-gray-900"}>Details</h3>
            <div className={"flex gap-8"}>
              <div  className={"flex-col mt-2"}>
                <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                  Name
                </div>
                <div className={"text-gray-500"}>
                  {customerData.name}
                </div>
              </div>
              <div  className={"flex-col mt-2"}>
                <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                  Account Number
                </div>
                <div className={"text-gray-500"}>
                  {customerData.account_no}
                </div>
              </div>
              <div  className={"flex-col mt-2"}>
                <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                  Assigned To
                </div>
                <div className={"text-gray-500"}>
                  {customerData.vtiger_assigned_to_name}
                </div>
              </div>
              <div className={"flex-col mt-2"}>
                <div className={"relative block text-sm mb-0 uppercase text-gray-700"}>
                  Last Sync Time
                </div>
                <div className={"text-gray-500"}>
                  {new Date(customerData.updated_at).toLocaleString()}
                </div>
              </div>
              { !isLoading && <div className={"flex mt-2 mb-6"}>
                <button
                  type="button"
                  onClick={async () => {
                    console.log(customerData.id, 'sync req.');
                    setIsLoading(true);
                    const e = await syncCustomer(customerData.id);
                    if (!e) {
                      setIsLoading(false);
                    }
                    else {
                      setErrors([e]);
                    }
                  }}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
                >
                  <Icon image={'refresh-cw'} className="mr-2" />
                  Sync Customer
                </button>
              </div>}
              { isLoading && <span className={"text-gray-600 text-md loading-trailing-dots"} ><Loader classNames={`w-6 h-6 inline-block mr-2`} />Syncing Customer...</span>}
            </div>
            <a
              type="button"
              href={`/customer/${customerData.id}/caid`}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
            >
              <Icon image={'list'} className="mr-2" />
              Manage CAID Scopes
            </a>
          </div>

          <h3 className={"text-xl font-semibold mb-4 mt-8 leading-6 text-gray-900"}>Data Specifications</h3>
          {customerData.dataSpecs && customerData.dataSpecs.length > 0 ?
            <ReactTable columns={dataSpecColumns} data={customerData.dataSpecs} /> :
            <p className={"ml-3"}>No Data Specs found in Datastand.</p>
          }

          <h3 className={"text-xl font-semibold mb-4 mt-8 leading-6 text-gray-900"}>Orders</h3>

          {customerData.orders && customerData.orders.length > 0 ?
            <ReactTable columns={orderColumns} data={customerData.orders}/> :
            <p className={"ml-3"}>No Orders found in Datastand.</p>
          }

        </>
      }
    </Fragment>
  )
}
