/***
*
*   HEADER
*   Header section with title used in main application (can render children)
*
*   PROPS
*   title: title of the view
*   children: children to render (optional)
*
**********/

import React, {useContext} from 'react'
import {AppLayoutContext} from "../layout/app/app";

export function Header(props){

  const [navButtons] = useContext(AppLayoutContext);

  return (
    <header className={ 'relative z-3 w-auto bg-white px-2 sm:px-4 lg:px-8 py-4 -mt-[1.5625em] -ml-[1.5625em] -mr-[1.5625em] mb-[1.5625em] ' + props.className}>

      <div className={"flex flex-col justify-center text-center md:flex-row md:justify-between"}>
        { props.title && <h1 className={"text-gray-900 text-lg sm:text-2xl font-bold"}>{ props.title }</h1> }
        { navButtons && (
          <div className={"flex justify-evenly mr-0 md:mr-16 lg:mr-60"}>
            {navButtons.map((nb) => { return (
              <button type="button"
                      key={nb.key}
                      onClick={() => nb.onClick()}
                      className={`inline-flex items-center px-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-${nb.color ? nb.color : 'orange'}-600 hover:bg-${nb.color ? nb.color : 'orange'}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 ${nb.className}`}>
                {nb.text}
              </button>);
            })}
          </div>)
        }
      </div>
      { props.children }

    </header>

  );
}
