import { Dashboard } from 'views/dashboard/dashboard';
import { AdminPanel } from 'views/adminPanel/adminPanel';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';
import { Customers } from 'views/customer/customers';
import { ViewCustomer } from 'views/customer/viewCustomer';
import { Orders } from 'views/orders/orders';
import { RedirectOrder } from 'views/orders/redirectOrder';
import { ViewOrder } from 'views/orders/viewOrder';
import { ConfigureCaidGeneration } from 'views/orders/configureCaidGeneration';
import { FactoryExport } from 'views/orders/factoryExport';
import { CreateDataSpec } from 'views/dataspec/createDataSpec';
import { ViewDataSpec } from 'views/dataspec/viewDataSpec';
import { DataSpecs } from 'views/dataspec/dataSpecs';
import { Caids } from "views/customer/caid/caids";
import { ViewCaidScope } from "views/customer/caid/viewCaidScope";

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/adminPanel',
    view: AdminPanel,
    layout: 'app',
    permission: 'user',
    title: 'Admin Panel'
  },
  {
    path: '/dataspec',
    view: DataSpecs,
    layout: 'app',
    permission: 'user',
    title: 'View Data Specifications'
  },
  {
    path: '/dataspec/new',
    view: CreateDataSpec,
    layout: 'app',
    permission: 'user',
    title: 'Create Data Specification'
  },
  {
    path: '/dataspec/:dataSpecId',
    view: ViewDataSpec,
    layout: 'app',
    permission: 'user',
    title: 'Edit Data Specification'
  },
  {
    path: '/orders',
    view: Orders,
    layout: 'app',
    permission: 'user',
    title: 'Orders'
  },
  {
    path: '/order/:orderId',
    view: ViewOrder,
    layout: 'app',
    permission: 'user',
    title: 'View Order'
  },
  {
    path: '/order/:orderId/generation/:orderItemId',
    view: ConfigureCaidGeneration,
    layout: 'app',
    permission: 'user',
    title: 'Configure CAID Generation'
  },
  {
    path: '/order/:orderId/factoryExport/:orderItemId',
    view: FactoryExport,
    layout: 'app',
    permission: 'user',
    title: 'Factory Data Export'
  },
  {
    path: '/rorder/:orderId',
    view: RedirectOrder,
    layout: 'app',
    permission: 'user',
    title: 'Sync Order'
  },
  {
    path: '/customers',
    view: Customers,
    layout: 'app',
    permission: 'user',
    title: 'Customers'
  },
  {
    path: '/customer/:customerId',
    view: ViewCustomer,
    layout: 'app',
    permission: 'user',
    title: 'View Customer'
  },
  {
    path: '/customer/:customerId/caid',
    view: Caids,
    layout: 'app',
    permission: 'user',
    title: 'Manage CAID Scopes'
  },
  {
    path: '/customer/:customerId/caid/:caidScopeId',
    view: ViewCaidScope,
    layout: 'app',
    permission: 'user',
    title: 'View CAID Scope'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
]

export default Routes;
