/***
 *
 *   Download
 *   Download template with features, testimonials and CTA
 *
 **********/

import React, {Fragment, useState} from 'react';
import { Row, Button, Hero, Features, Testimonial } from 'components/lib';
import axios from "axios";
import {useLocation} from "react-router-dom";
import fileDownload from "js-file-download";

export function Download(props) {

    const query = useQuery();
    const fileName = query.get('fileName');
    const [msg, setMsg] = useState("File is Downloading......");
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    function removeTokenFromFileName(fileName) {
        // Regular expression to match a long alphanumeric string preceded by a hyphen
        const regex = /-[a-fA-F0-9]{32,}/;

        // Replace the token in the filename
        const newFileName = fileName.replace(regex, '');

        return newFileName;
    }

    if(fileName!=null || fileName!==''){
        getCSV(fileName);
    }

    async function getCSV(fileName) {
        let massage
        const response = await axios({
            method: 'get',
            url: `/api/download/`+fileName,
            responseType: 'text',
        });

        if (response.data && response.data.trim().length > 0) {
            let newFileName= removeTokenFromFileName(fileName);
            fileDownload(response.data, newFileName);
            setMsg("Thank you for downloading the file.");
        } else {
            setMsg("File not found.");
        }

    }


    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',      // Center vertically
        height: '60vh',           // Full viewport height
        width: '100%',             // Full width of the parent
        textAlign: 'center'        // Center text alignment
    };


    return (
        <Fragment>
            <div style={containerStyle}>
                <p>{msg}</p>
            </div>
        </Fragment>
    );
}
