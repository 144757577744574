/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React from 'react';
import {Animate, Content, Button, Image, ClassHelper, Logo} from 'components/lib';
import Style from './hero.tailwind.js';

export function Hero(props){

  const heroStyle = ClassHelper(Style, {

    hero: true,
    className: props.className

  });

  return (
    <section className={ heroStyle }>
      <Content>

        <Animate type='pop'>
          <Logo />
          <section className={ Style.blurb }>

            <h1 className={ Style.title }>{ props.title }</h1>
            <h2 className={ Style.tagline }>{ props.tagline }</h2>
            <div className={"flex justify-center"}>
              <Button goto='/signup' text='Sign Up Now' big className={ Style.button + " m-2 mb-3 bg-indigo-500 hover:bg-indigo-700"}/>
              <Button goto='/signin' text='Sign In' big className={ Style.button + " m-2 mb-3 bg-emerald-500 hover:bg-emerald-700"}/>
              <Button goto='/Dashboard' text='Go to Dashboard' big className={ Style.button + " m-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>
            </div>

          </section>
        </Animate>

        <Animate>
          <Image className={ Style.image } source={ props.image } alt={ props.alt } />
        </Animate>

      </Content>
    </section>
  )
}
