/***
 *
 *   VIEW/EDIT DATA SPEC
 *   View and edit a data specification
 *
 **********/

import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  AppLayoutContext, AuthContext,
  DateCell, ErrorMessage,
  Form,
  HyperlinkNewTabCell,
  Icon,
  Loader,
  ReactTable,
  TextInput,
  useAPI,
  useDidMount, ViewContext
} from 'components/lib';
import {Tabs} from "./shared/Tabs";
import {PrintConfig} from './shared/PrintConfig';
import {ChipConfig} from './shared/ChipConfig';
import {ChipModal} from './shared/ChipModal';
import {
  hasChipVariableData,
  hasSharedDataSource,
  hasPrintingVariableData,
  isChipUidReferenced,
  hasChipDataLocking,
  hasChipCollectUID
} from './shared/util';
import axios from "axios";
import debounce from 'lodash.debounce';

import './styles.css';
import {PreviewPdf} from "./export/PreviewPdf";
import {OrderNumberCell} from "../orders/orders";
import Style from "../../components/form/file/file.tailwind";
import {Dialog, Transition} from "@headlessui/react";

const IS_FROZEN_STATE = {
  FROZEN: 0,
  NOT_FROZEN: 1,
  LOADING: 2,
}

// TODO: this is kinda loose, needs to stay in-sync with Vtiger SO Status options
const NON_FROZEN_STATUSES = ['Created', 'Submitted', 'Review and Approval Needed', 'Approved', 'Reopened'];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function ViewDataSpec(props){
  const { dataSpecId } = useParams();
  const dataSpecFromServer = useAPI('/api/dataSpec/' + dataSpecId);
  const linkedOrdersFromServer = useAPI('/api/dataSpec/' + dataSpecId + '/orders');
  const dataListColumnsFromServer = useAPI(`/api/dataSpec/${dataSpecId}/dataListColumns`);
  const transponderModels = useAPI('/api/transponderModels/');
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');
  const dataListSources = useAPI('/api/dataListSources/');
  const context = useContext(ViewContext);
  const userContext = useContext(AuthContext);
  let [pendingData, setPendingData] = useState(true);
  let [dataSpec, setDataSpec] = useState(null);
  let [printConfig, setPrintConfig] = useState(null);
  let [chipConfigs, setChipConfigs] = useState([]);
  let [lastSaveTime, setLastSaveTime] = useState(null);
  let [isFrozen, setIsFrozen] = useState(IS_FROZEN_STATE.LOADING);
  let [ordersCausingFrozen, setOrdersCausingFrozen] = useState('');
  let [showDeleteModal, setShowDeleteModal] = useState(false);
  let [showFrozenOverrideModal, setShowFrozenOverrideModal] = useState(false);
  let [hasOverrideFrozen, setHasOverrideFrozen] = useState(false);

  // Data List Columns
  let [dataListColumns, setDataListColumns] = useState([]);
  let [columnNameInput, setColumnNameInput] = useState('');
  let [columnSampleValueInput, setColumnSampleValueInput] = useState('');
  let [descriptionInput, setDescriptionInput] = useState('');

  let [columnErrors, setColumnErrors] = useState(null);

  const PRINTING_TAB = { name: 'Printing', href: '#', id: 0, key: 'PRINTING_TAB_KEY', badge: 0, current: true };
  const NO_DATA_TAB = { name: 'No Data', href: '#', id: 0, key: 'NO_DATA', current: true };
  const ADD_PRINTING_TAB = { name: 'Add Printing', icon: 'plus', href: '#', id: 0, key: 'ADD_PRINT_TAB_KEY', current: false, onClick: addPrintingClick };
  const ADD_CHIP_TAB = { name: 'Add Chip', icon: 'plus', href: '#', id: -1, key: 'ADD_CHIP_TAB_KEY', current: false, onClick: addChipClick };


  useEffect(() => {
    if (!dataSpecFromServer.loading && dataSpecFromServer.data) {
      setPrintConfig(dataSpecFromServer.data.printConfig);
      setChipConfigs(dataSpecFromServer.data.transponderConfigs);
      setDataSpec(dataSpecFromServer.data);
      setPendingData(false);
      console.log('from server: ', dataSpecFromServer); // TODO: remove after testing
    }
  }, [dataSpecFromServer]);

  useEffect(() => {
    if (!dataListColumnsFromServer.loading && dataListColumnsFromServer.data) {
      setDataListColumns(dataListColumnsFromServer.data);
    }
  }, [dataListColumnsFromServer]);

  useEffect(() => {
    if (linkedOrdersFromServer.data) {
      let isFrozenVar = false;
      let orderNumbers = [];
      linkedOrdersFromServer.data.forEach(order => {
        if (!NON_FROZEN_STATUSES.includes(order.vtiger_sostatus)) {
          console.log(order)
          orderNumbers.push(order.vtiger_salesorder_no);
          isFrozenVar = true;
        }
      });
      setIsFrozen(isFrozenVar ? IS_FROZEN_STATE.FROZEN : IS_FROZEN_STATE.NOT_FROZEN);

      // console.log(orderNumbers.join(' '))
      setOrdersCausingFrozen(orderNumbers.join(', '));
    }
  }, [linkedOrdersFromServer])

  async function duplicateSpec() {
    // send create request to server
    const response = await axios({
      method: 'post',
      url: '/api/dataspec/' + dataSpecId,
      data: {
        dataSpec: {
          ...dataSpec,
          created_by_id: userContext.user?.user_id,
        },
        otherData: {
          duplicate_id: true,
          existingDataSpecId: dataSpecId,
        }
      },

    });

    if (response.status === 200) {
      context.notification.show("DataSpec Has been duplicated.", 'success', true);
      // Navigate to New Spec
      navigate(`/dataSpec/${response.data.data.id}`);
    } else if (response.status !== 200) {
      // return error if not '200 OK'
      return [{
        errorStatus: response.status,
        errorStatusText: response.statusText,
        message: 'Error when creating data spec.'
      }]
    }
  }


  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([{text: 'Export to PDF', color: 'orange', key: 'pdf_preview_button', onClick: () => setOpenPdfPreview(true)},
      {text: 'Duplicate Spec', color: 'orange', className:'ml-3', onClick: () => duplicateSpec(true)}]);
  }, []);


  useEffect(() => {
    let latestTimestamp;
    if (dataSpec) {
      latestTimestamp = new Date(dataSpec.updated_at);

      if (printConfig) {
        if (new Date(printConfig.updated_at) > latestTimestamp) {
          latestTimestamp = new Date(printConfig.updated_at);
        }
        printConfig.printRecords.forEach(pr => {
          if (new Date(pr.updated_at) > latestTimestamp) {
            latestTimestamp = new Date(pr.updated_at);
          }
        });
      }

      chipConfigs.forEach(cc => {
        if (new Date(cc.updated_at) > latestTimestamp) {
          latestTimestamp = new Date(cc.updated_at);
        }
        cc.dataRecords.forEach(dr => {
          if (new Date(dr.updated_at) > latestTimestamp) {
            latestTimestamp = new Date(dr.updated_at);
          }
        });
      });

      setLastSaveTime(latestTimestamp.toLocaleString());
    }
  }, [dataSpec, printConfig ? JSON.stringify(printConfig): printConfig, JSON.stringify(chipConfigs)]);
  // ^ Usage of JSON.stringify() in dep array explained here: https://github.com/facebook/react/issues/14476#issuecomment-471199055

  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  let [activeTab, setActiveTab] = useState(printConfig ? 0 : 1);
  let [tabs, setTabs] = useState([NO_DATA_TAB]);
  let [preTabButtons, setPreTabButtons] = useState([ADD_PRINTING_TAB]);
  let [postTabButtons, setPostTabButtons] = useState([ADD_CHIP_TAB]);

  let [expandLinkedOrders, setExpandLinkedOrder] = useState(false);

  // Add/edit chip modal state
  function getDefaultChipData() {
    return {
      data_spec_id: dataSpecId,
      instructions: '',
      lock_all: false,
      collect_chip_uid: false,
      dataRecords: [],
    }
  }
  const [openChipModal, setOpenChipModal] = useState(false);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  // This is the data that the modal loads with
  const [modalContext, setModalContext] = useState({ data: getDefaultChipData(), isEdit: false });

  // Update tabs when chipConfigs change
  useEffect(() => {
    if (!pendingData && (chipConfigs || printConfig)) {
      let printTab = null;
      if (printConfig) {
        // convert print records to tabs
        printTab = {
          name: 'Printing',
          href: '#',
          id: 0,
          key: 'PRINTING_TAB_KEY',
          badge: printConfig.printRecords ? printConfig.printRecords.length : 0,
          current: tabs[0].current
        };
      }

      // convert chipConfigs to tabs
      const chipModelCounts = {};
      const chipTabs = chipConfigs.sort((a,b) => new Date(a.created_at) - new Date(b.created_at)) // order by created time
          .map((cc,index) => {
            let modelCount = 1;
            if (!chipModelCounts[cc.transponder_model_id]) {
              chipModelCounts[cc.transponder_model_id] = 1;
            } else {
              modelCount = chipModelCounts[cc.transponder_model_id] + 1;
              chipModelCounts[cc.transponder_model_id]++;
            }

            let ccTabName = (!transponderModels.loading && transponderModels.data && transponderModels.data.find(x => parseInt(x.id) === parseInt(cc.transponder_model_id))) ?
                `${transponderModels.data.find(x => parseInt(x.id) === parseInt(cc.transponder_model_id)).name}${chipModelCounts[cc.transponder_model_id] > 1 ? ' [' + modelCount + ']' : ''}` :
                `loading ${index}`;

            return {
              name: ccTabName, href: '#', id: index+(printTab ? 1:0), current:(index+(printTab ? 1:0)) === activeTab, key: cc.id, badge: cc.dataRecords.length
            }
          });

      if (chipConfigs.length === 0 && !printConfig) {
        // setTabs([ADD_PRINTING_TAB, NO_DATA_TAB, tabs[tabs.length - 1]]);
        setTabs([NO_DATA_TAB]);

        setPreTabButtons([ADD_PRINTING_TAB]);
        setPostTabButtons([ADD_CHIP_TAB]);
        // console.log('Update tabs when chipConfigs change (1): ',[ADD_PRINTING_TAB],[NO_DATA_TAB],[ADD_CHIP_TAB])
      } else if (printTab) {
        // setTabs([printTab, ...chipTabs, tabs[tabs.length - 1]]);
        setTabs([printTab, ...chipTabs]);
        setPreTabButtons([]);
        setPostTabButtons([ADD_CHIP_TAB]);
        // console.log('Update tabs when chipConfigs change (2): ',[],[printTab, ...chipTabs],[ADD_CHIP_TAB])
      } else {
        setTabs([...chipTabs]);
        setPreTabButtons([ADD_PRINTING_TAB]);
        setPostTabButtons([ADD_CHIP_TAB]);
        // console.log('Update tabs when chipConfigs change (3): ',[ADD_PRINTING_TAB],[...chipTabs],[ADD_CHIP_TAB])
      }
    }
  }, [pendingData, chipConfigs, printConfig, transponderModels]);

  useEffect(() => {
    if (activeTab > tabs.length - 1) {
      // console.log('reset activeTab', activeTab, '>', tabs.length, '-1')
      setActiveTab(0);
    }
  }, [tabs, activeTab]);

  function OrderNumberCell({ row, value }) {
    // console.log(row)

    return (
        <a href={`/order/${row.original.id}`} className="hover:underline text-sm text-orange-500">
          {value}
        </a>
    );
  }

  const orderColumns = React.useMemo(() => [
    {
      Header: "Order Number",
      accessor: 'vtiger_salesorder_no',
      Cell: OrderNumberCell,
    },
    {
      Header: "Organization",
      id: "customer_id",
      accessor: (row) => {
        return row && row.customer_id && customers.data ? customers.data.find(u => u.id === row.customer_id).name: row.customer_id
      },
    },
    {
      Header: "Status",
      accessor: 'vtiger_sostatus',
      // Cell: StatusPill,
    },
    {
      Header: "vTiger Created Time",
      accessor: 'vtiger_createdtime',
      Cell: DateCell,
    },
    {
      Header: "vTiger Modified Time",
      accessor: 'vtiger_modifiedtime',
      Cell: DateCell,
    },
    {
      Header: "Last Sync",
      accessor: 'updated_at',
      Cell: DateCell,
    },
    {
      Header: "vTiger Link",
      accessor: 'external_link',
      Cell: HyperlinkNewTabCell,
    },
  ], [customers])

  async function addChipClick() {
    // handle add chip click
    setModalContext({
      data: getDefaultChipData(),
      isEdit: false,
    });
    setOpenChipModal(true);
  }
  async function addPrintingClick() {
    let newPrintConfig = {
      data_spec_id: dataSpecId,
      instructions: '',
      printRecords: [],
    };

    // handle add printing click
    const response = await axios({

      method: 'post',
      url: '/api/printConfig/',
      data: {data: newPrintConfig},

    });

    console.log(response);
    if (response.status === 200) {
      // Update state
      setPrintConfig(response.data.data);
      setActiveTab(0);
    }
    else if (response.status !== 200) {
      // return error if not '200 OK'
      console.log([{
        errorStatus: response.status,
        errorStatusText: response.statusText,
        message: 'Error when creating print config.'
      }]);
    }
  }
  async function handleTabClick (selectedId, selectedKey) {
    setActiveTab(selectedId);
  }

  const debouncedUpdateDataSpec = useMemo(
      () => debounce(updateDataSpec, 300)
      , []);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      debouncedUpdateDataSpec.cancel();
    }
  }, []);

  function validateDataSpec () {
    // TODO: implement
    return {errors: [], success: true};
  }

  async function updateDataSpec(dataSpec, key, value) {
    const validationResult = validateDataSpec({dataSpec, printConfig, chipConfigs});

    if (validationResult.errors && validationResult.errors.length > 0) {
      // TODO: display error message
    }
    else { // Passed validation, send request

      const response = await axios({

        method: 'patch',
        url: '/api/dataSpec/' + dataSpec.id,
        data: {fullRecord: dataSpec, key, value}

      });

      console.log(response); // TODO: remove after testing
      if (response.status === 200) {
        // Update state
        // console.log('set dataSpec from server ', response.data.data)

        // Update timestamp
        setDataSpec(prev => {
          return {
            ...prev,
            updated_at: response.data.data.updated_at
          }
        });
      }
      // TODO: on error, rollback changes
    }
  }

  async function addListColumn(name, sampleValue, description) {

    if (!name) {
      return "Column Name is required.";
    }
    else { // Passed validation, send request

      const response = await axios({

        method: 'post',
        url: `/api/dataSpec/${dataSpec.id}/dataListColumns`,
        data: {name: name, sample_value: sampleValue, description: description}

      });

      console.log(response); // TODO: remove after testing
      if (response.status === 200) {
        setDataListColumns(prev => [...prev, response.data.data]);

        // TODO: This action should also update DataSpec timestamp
      }
      // TODO: on error, rollback changes
    }
  }


  async function deleteListColumn(id) {

    if (!id) {
      return;
    }
    else { // Passed validation, send request
      try {
        const response = await axios({

          method: 'delete',
          url: '/api/dataSpec/dataListColumns/' + id,

        });
        console.log(response); // TODO: remove after testing
        if (response.status === 200) {

          // HACK: this is a hack around not properly cleaning up stale state in record modals and possibly other child components
          window.location.reload();

          // setDataListColumns(prev => prev.filter(x => x.id !== id));
          // // Remove invalid data source references
          // // TODO: this cleans up config data, but modal state is still stale...
          // setPrintConfig(prev => {
          //   return {
          //     ...prev,
          //     printRecords: prev.printRecords.map(pr => {
          //       return {
          //         ...pr,
          //         data_sources: pr.data_sources.filter(x => x.id !== id),
          //       };
          //     }),
          //   };
          // });
          // setChipConfigs(prev => {
          //   return prev.map(cc => {
          //     return {
          //       ...cc,
          //       dataRecords: cc.dataRecords.map(dr => {
          //         return {
          //           ...dr,
          //           data_sources: dr.data_sources.filter(x => x.id !== id),
          //         };
          //       }),
          //     };
          //   });
          // });

          // TODO: This action should also update DataSpec timestamp
        }
      } catch (err) {
        return err;
      }
    }
  }



  async function deleteDataSpec(dataSpecId) {

    // Validate
    const errors = [];
    if (!dataSpecId) {
      errors.push('dataSpecId was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    try {
      // send create request to server
      const response = await axios({

        method: 'delete',
        url: `/api/dataSpec/${dataSpecId}`,

      });

      console.log(response);
      if (response.status === 200) {
        return navigate(`/dataspec/`);
      }
      else if (response.status !== 200) {
        // return error if not '200 OK'
        return [{
          errorStatus: response.status,
          errorStatusText: response.data.error,
          message: 'Error when deleting Data Spec.'
        }]
      }
    } catch (e) {
      let errorDetails = '';
      if (e.response?.data?.message && e.response.data.message.includes("violates foreign key")) {
        errorDetails = "Cannot delete Data Spec because it is linked to existing orders."
      }
      else if (e.response?.data?.message) {
        errorDetails = e.response?.data?.message;
      }

      return [{
        additionalErrorText: errorDetails,
        message: 'Error when deleting Data Spec.'
      }]
    }
  }

  async function updateListColumn(id, name, sample_value, description) {
    if (!name) {
      return "Column Name is required.";
    }
    else { // Passed validation, send request

      console.log("API Call trigger");
      const response = await axios({

        method: 'patch',
        url: `/api/dataSpec/dataListColumns/${id}`,
        data: {id: id, name: name, sample_value: sample_value, description: description}

      });
      console.log("Column Data");
      console.log(dataListColumns);
      const data = dataListColumns.map(obj =>
          obj.id === id ? { ...obj, name: name, sample_value: sample_value, description: description } : obj
      );
      console.log(data);
      console.log(response.data.data); // TODO: remove after testing
      if (response.status === 200) {
        setDataListColumns(data);

        // TODO: This action should also update DataSpec timestamp
      }
      // TODO: on error, rollback changes
    }
  }

  function DataListColumns(props) {
    // console.log("Datalist Column: "+ JSON.stringify(props))
    let [columnErrors, setColumnErrors] = useState(null);
    let [columnEdit, setColumnEdit] = useState(null);
    let [columnNameInput, setColumnNameInput] = useState('');
    let [columnSampleValueInput, setColumnSampleValueInput] = useState('');
    let [descriptionInput, setDescriptionInput] = useState('');

    return (<div className={"my-4 " + props.className}>
          {columnErrors && columnErrors.length > 0 && <ErrorMessage errors={columnErrors} dismissOnClick={() => setColumnErrors(null)} className={"mt-4"} />}
          <ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200">
            {props.records.map((record, index) => (
                <li key={record.id}>
                  { columnEdit != record.id  &&
                      <div className="flex items-center py-2 px-2">
                        <div className="min-w-0 flex-1 flex items-center">
                          <div className="min-w-0 flex-1 flex items-center justify-start">
                            <div className="min-w-0 px-4">
                              <div className={"flex"}>
                                <div className={"text-red-600 cursor-pointer"} onClick={async () => {
                                  let error = await props.deleteColumn(record.id)
                                  if (error) {
                                    setColumnErrors([error.toString()]);
                                  }
                                }}>
                                  <Icon image={'trash'} size={18} className={Style.labelIcon}/>
                                </div>
                                <div className={"text-red-600 cursor-pointer"} onClick={
                                  () => {
                                    if(isFrozen === IS_FROZEN_STATE.NOT_FROZEN){
                                      setColumnEdit(record.id);
                                      setColumnNameInput(record.name);
                                      setColumnSampleValueInput(record.sample_value);
                                      setDescriptionInput(record.description);
                                    }
                                  }
                                }><Icon image={'edit'} size={18} className={Style.labelIcon}/></div>
                              </div>
                            </div>
                            <div className="min-w-0 px-4">
                              <div>
                                <p className="text-sm font-medium text-gray-500 truncate">Column {index + 1}</p>
                                <p className="mt-2 flex items-center text-sm text-gray-700">
                                  <span className="truncate">{record.name}</span>
                                </p>
                              </div>
                            </div>

                            {record.sample_value && <div className="min-w-0 px-4">
                              <div>
                                <p className="text-sm font-medium text-gray-500 truncate">Sample Output</p>
                                <p className="mt-2 flex items-center text-sm text-gray-700">
                                  <span className="truncate">{record.sample_value}</span>
                                </p>
                              </div>
                            </div>}

                            {record.description && <div className="min-w-0 px-4">
                              <div>
                                <p className="text-sm font-medium text-gray-500 truncate">Description</p>
                                <p className="mt-2 flex items-center text-sm text-gray-700">
                                  <span className="truncate">{record.description}</span>
                                </p>
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>
                  }

                  {(columnEdit === record.id) &&
                      <div className={"flex mt-4 ml-3 editDatalist"}>
                        <TextInput placeholder={"Column Name"} value={columnNameInput} onChange={(name, value) => setColumnNameInput(value) } outerClassName={"mr-2"} />
                        <TextInput placeholder={"Sample Value"} value={columnSampleValueInput} onChange={(name, value) => setColumnSampleValueInput(value) } outerClassName={"mr-2"} />
                        <TextInput placeholder={"Description"} value={descriptionInput} onChange={(name, value) => setDescriptionInput(value) } outerClassName={"mr-2"} />
                        <button
                            type="button"
                            onClick={async () => {
                              console.log("State change to hide data and show data");
                              const e = await updateListColumn(record.id, columnNameInput, columnSampleValueInput, descriptionInput);

                              if (!e) {
                                // setColumnErrors(null);
                                setColumnEdit(null);
                                setColumnNameInput('');
                                setColumnSampleValueInput('');
                                setDescriptionInput('');
                                console.log("Error on submitting data")
                              }
                              else {
                                // setColumnErrors([e]);
                                console.log("Success")
                              }
                            }}
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
                        >
                          Update Data List Column
                        </button>
                        <button
                            type="button"
                            onClick={ () => setColumnEdit(null) }
                            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
                        >
                          Cancel
                        </button>
                      </div>
                  }

                </li>
            ))}
          </ul>
          <div className={"border-t"} />
        </div>
    );
  }


  // console.log('re-render', 'activeTab:', activeTab, 'tabs:', tabs.length, JSON.stringify(tabs.map(t =>{ return {name:t.name, id:t.id}})));
  return (
      <Fragment>
        {pendingData || dataSpec == null || dataSpec.loading || !users.data || !customers.data ?
            <Loader/> :
            <>
              <TransitionGroup>
                <CSSTransition key={lastSaveTime} timeout={1000} classNames="timestamp">
                  <div className={"fixed w-full text-center -ml-[25px] -mt-[22px] md:w-fit md:text-left md:ml-64 md:-mt-20 md:p-4 md:z-[500] text-gray-400 text-sm"}>
                    Last Saved: { dataSpec && lastSaveTime }
                  </div>
                </CSSTransition>
              </TransitionGroup>
              <div className={"p-4 bg-white my-4 shadow sm:rounded-lg"}>
                <h3 className={`text-xl font-semibold ml-2 leading-6 text-gray-900 ${linkedOrdersFromServer.data && linkedOrdersFromServer.data.length > 0 ? 'cursor-pointer' : ''}`} onClick={() => {
                  if (linkedOrdersFromServer.data && linkedOrdersFromServer.data.length > 0) {
                    setExpandLinkedOrder(prev => !prev);
                  }
                }}>
                  {isFrozen === IS_FROZEN_STATE.LOADING ? <span className={"text-gray-600 loading-trailing-dots"} ><Loader classNames={`w-6 h-6 inline-block mr-2`} />Syncing Link Orders...</span>
                      :
                      <>
                        <span className={`${linkedOrdersFromServer.data && linkedOrdersFromServer.data.length > 0 ? '' : 'text-gray-600'}`}>{linkedOrdersFromServer.data && linkedOrdersFromServer.data.length > 0 ? linkedOrdersFromServer.data.length + ' ': 'No '}Linked Orders</span>
                        {linkedOrdersFromServer.data && linkedOrdersFromServer.data.length > 0 && <span className={"ml-2 text-orange-500"} >{expandLinkedOrders ? '[-]': '[+]'}</span>}
                        {hasOverrideFrozen && <span className={"ml-2 text-gray-400 text-sm"}>Frozen state has been temporarily OVERRIDDEN</span>}
                        {isFrozen === IS_FROZEN_STATE.FROZEN && <><span className={"ml-2 text-gray-400 text-sm"} >Data Spec is FROZEN due to: {ordersCausingFrozen}</span>
                          <span className={"ml-2 text-gray-500 text-sm underline"} onClick={(e) => {
                            setShowFrozenOverrideModal(true);
                            e.stopPropagation();
                          }}>Override</span></>}
                      </>
                  }
                </h3>
                { expandLinkedOrders && <div className={"mx-6 mt-4"}>
                  { !linkedOrdersFromServer.data ?
                      <Loader /> :
                      <ReactTable columns={orderColumns} data={linkedOrdersFromServer.data} />
                  }
                </div>}
              </div>
              <div className={`p-4 pb-6 my-4 shadow sm:rounded-lg ${isFrozen === IS_FROZEN_STATE.LOADING ? "bg-gray-50" : "bg-white"}`}>
                <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Details</h3>
                <Form
                    updateOnChange={true}
                    onChange={(e) => {
                      if (e.value !== dataSpec[e.input]) {
                        // in case isNullable, then set to null
                        let updatedValue = e.value === 'unselected' ? null : e.value;
                        let updatedDs = {
                          ...dataSpec,
                          [e.input]: updatedValue
                        };
                        setDataSpec(updatedDs);
                        debouncedUpdateDataSpec(updatedDs, e.input, updatedValue);
                      }
                    }}
                    // className={"space-y-6"}
                    flex={true}
                    data={{
                      customer_id: {
                        label: 'Organization',
                        type: 'select',
                        options: (!customers.loading && customers.data) ? customers.data.map(x => {return {value: x.id, label: x.name}}) : [],
                        required: true,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        value: dataSpec.customer_id || undefined,
                        default: dataSpec.customer_id,
                        // valid: fieldsSaveSuccess.find(f => f === 'customer') ? true : null,
                        // validate: () => null,
                        errorMessage: 'Please select an organization',
                      },
                      number: {
                        label: 'Data Specification Number',
                        type: 'text',
                        // required: true,
                        readonly: true,
                        value: dataSpec.number,
                        // valid: fieldsSaveSuccess.find(f => f === 'number') ? true : null,
                        // validate: () => null,
                        errorMessage: 'Please enter a DS number',
                      },
                      description: {
                        label: 'Description',
                        type: 'textarea',
                        required: false,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        value: dataSpec.description || undefined,
                        // valid: fieldsSaveSuccess.find(f => f === 'description') ? true : null,
                        // validate: () => null,
                        errorMessage: 'Please enter a description',
                      },
                      // is_locked: {
                      //   label: 'Is Frozen',
                      //   type: 'singleCheckbox',
                      //   required: false,
                      //   readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                      //   value: dataSpec.is_locked,
                      //   errorMessage: '',
                      // },
                      data_deliverables_customer: {
                        label: 'Data Deliverables (Customer)',
                        type: 'text',
                        required: false,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        value: dataSpec.data_deliverables_customer,
                        errorMessage: 'Please enter a value',
                      },
                      data_deliverables_internal: {
                        label: 'Data Deliverables (Internal)',
                        type: 'text',
                        required: false,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        value: dataSpec.data_deliverables_internal,
                        errorMessage: 'Please enter a value',
                      },
                      // assigned_to: {
                      //   label: 'Assigned To',
                      //   type: 'text',
                      //   required: false,
                      //   value: dataSpec.assigned_to,
                      //   errorMessage: 'Please select a user',
                      // },
                      // created_by: {
                      //   label: 'Created By',
                      //   type: 'text',
                      //   required: false,
                      //   value: dataSpec.created_by,
                      //   errorMessage: 'Please select a user',
                      // },
                      assigned_to_id: {
                        label: 'Assigned To',
                        type: 'select',
                        options: (!users.loading && users.data) ? users.data.map(x => {return {value: x.id, label: x.name}}) : [],
                        required: false,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        value: dataSpec.assigned_to_id || undefined,
                        default: dataSpec.assigned_to_id,
                        errorMessage: 'Please select a user',
                      },
                      created_by_id: {
                        label: 'Created By',
                        type: 'select',
                        options: (!users.loading && users.data) ? users.data.map(x => {return {value: x.id, label: x.name}}) : [],
                        required: false,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        disabled: true,
                        value: dataSpec.created_by_id || undefined,
                        default: dataSpec.created_by_id,
                        errorMessage: 'Please select a user',
                      },
                    }}>
                </Form>
                <div className={"mt-8"}>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Key Attributes</h3>
                  <p className="text-sm text-gray-400">
                    These attributes are auto-calculated based on specifications
                  </p>
                  <dl className="mt-5 max-w-[1080px] grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                    <div key='info-box-print-encoding-match' className={classNames(hasSharedDataSource(printConfig, chipConfigs) ? 'bg-white' : 'bg-gray-50', "px-4 py-5 sm:p-6")}>
                      <dt className="text-base font-normal text-gray-900">Match Printing and Encoding</dt>
                      <div className="flex mb-3 text-gray-400 items-center">
                        <p className="text-sm min-h-[40px] text-gray-400">
                          True if both printing and encoding records reference variable data (data source or Chip UID).
                        </p>
                      </div>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-lg font-semibold">
                          {hasSharedDataSource(printConfig, chipConfigs) ?
                              <div className={"flex items-center text-green-500"}>
                                <Icon
                                    image='check'
                                    size={ 24 }
                                />
                                <span>YES</span>
                              </div>:
                              <div className={"flex items-center text-red-500"}>
                                <Icon
                                    image='x'
                                    size={ 24 }
                                />
                                <span>NO</span>
                              </div>
                          }
                        </div>
                      </dd>
                    </div>
                    <div key='info-box-printing-variable' className={classNames(hasPrintingVariableData(printConfig) ? 'bg-white' : 'bg-gray-50', "px-4 py-5 sm:p-6")}>
                      <dt className="text-base font-normal text-gray-900">Printing Variable Data</dt>
                      <div className="flex mb-3 text-gray-400 items-center">
                        <p className="text-sm min-h-[40px] text-gray-400">
                          True if any printing record references a data source or Chip UID.
                        </p>
                      </div>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-lg font-semibold text-indigo-600">
                          {hasPrintingVariableData(printConfig) ?
                              <div className={"flex items-center text-green-500"}>
                                <Icon
                                    image='check'
                                    size={ 24 }
                                />
                                <span>YES</span>
                              </div>:
                              <div className={"flex items-center text-red-500"}>
                                <Icon
                                    image='x'
                                    size={ 24 }
                                />
                                <span>NO</span>
                              </div>
                          }
                        </div>
                      </dd>
                    </div>
                    <div key='info-box-encoding-variable' className={classNames(hasChipVariableData(chipConfigs) ? 'bg-white' : 'bg-gray-50', "px-4 py-5 sm:p-6")}>
                      <dt className="text-base font-normal text-gray-900">Encoding Variable Data</dt>
                      <div className="flex mb-3 text-gray-400 items-center">
                        <p className="text-sm min-h-[40px] text-gray-400">
                          True if any encoding record references a data source or Chip UID.
                        </p>
                      </div>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-lg font-semibold text-indigo-600">
                          {hasChipVariableData(chipConfigs) ?
                              <div className={"flex items-center text-green-500"}>
                                <Icon
                                    image='check'
                                    size={ 24 }
                                />
                                <span>YES</span>
                              </div>:
                              <div className={"flex items-center text-red-500"}>
                                <Icon
                                    image='x'
                                    size={ 24 }
                                />
                                <span>NO</span>
                              </div>
                          }
                        </div>
                      </dd>
                    </div>

                  </dl>


                  <dl className="mt-2 max-w-[1080px] grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-y-0 md:divide-x">
                    <div key='info-box-has-chip-uid' className={classNames(isChipUidReferenced(printConfig, chipConfigs) ? 'bg-white' : 'bg-gray-50', "px-4 py-5 sm:p-6")}>
                      <dt className="text-base font-normal text-gray-900">Data Uses Chip UID</dt>
                      <div className="flex mb-3 text-gray-400 items-center">
                        <p className="text-sm min-h-[40px] text-gray-400">
                          True if any print or encoding record references Chip UID.
                        </p>
                      </div>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-lg font-semibold text-indigo-600">
                          {isChipUidReferenced(printConfig, chipConfigs) ?
                              <div className={"flex items-center text-green-500"}>
                                <Icon
                                    image='check'
                                    size={ 24 }
                                />
                                <span>YES</span>
                              </div>:
                              <div className={"flex items-center text-red-500"}>
                                <Icon
                                    image='x'
                                    size={ 24 }
                                />
                                <span>NO</span>
                              </div>
                          }
                        </div>
                      </dd>
                    </div>
                    <div key='info-box-chip-locking' className={classNames(hasChipDataLocking(chipConfigs) ? 'bg-white' : 'bg-gray-50', "px-4 py-5 sm:p-6")}>
                      <dt className="text-base font-normal text-gray-900">Requires Chip Locking</dt>
                      <div className="flex mb-3 text-gray-400 items-center">
                        <p className="text-sm min-h-[40px] text-gray-400">
                          True if any chip configuration requires data locking.
                        </p>
                      </div>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-lg font-semibold text-indigo-600">
                          {hasChipDataLocking(chipConfigs) ?
                              <div className={"flex items-center text-green-500"}>
                                <Icon
                                    image='check'
                                    size={ 24 }
                                />
                                <span>YES</span>
                              </div>:
                              <div className={"flex items-center text-red-500"}>
                                <Icon
                                    image='x'
                                    size={ 24 }
                                />
                                <span>NO</span>
                              </div>
                          }
                        </div>
                      </dd>
                    </div>
                    <div key='info-box-collect-chip-id' className={classNames(hasChipCollectUID(chipConfigs) ? 'bg-white' : 'bg-gray-50', "px-4 py-5 sm:p-6")}>
                      <dt className="text-base font-normal text-gray-900">Collect Chip UID</dt>
                      <div className="flex mb-3 text-gray-400 items-center">
                        <p className="text-sm min-h-[40px] text-gray-400">
                          True if any chip UID needs to be collected during production.
                        </p>
                      </div>
                      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-lg font-semibold text-indigo-600">
                          {hasChipCollectUID(chipConfigs) ?
                              <div className={"flex items-center text-green-500"}>
                                <Icon
                                    image='check'
                                    size={ 24 }
                                />
                                <span>YES</span>
                              </div>:
                              <div className={"flex items-center text-red-500"}>
                                <Icon
                                    image='x'
                                    size={ 24 }
                                />
                                <span>NO</span>
                              </div>
                          }
                        </div>
                      </dd>
                    </div>

                  </dl>
                </div>
              </div>
              <div className={`p-4 pb-6 my-4 shadow sm:rounded-lg ${isFrozen === IS_FROZEN_STATE.LOADING ? "bg-gray-50" : "bg-white"}`}>
                <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Data List</h3>
                <p className="ml-2 text-sm text-gray-400">
                  For orders that require a data list, specify the configuration of the data list
                </p>
                <Form
                    // buttonText='Create'
                    // url={'/api/dataSpec' + (searchParams.get('parent') ? '/' + searchParams.get('parent') : '')}
                    // method='POST'
                    updateOnChange={true}
                    onChange={(e) => {
                      if (e.value !== dataSpec[e.input]) {
                        // in case isNullable, then set to null
                        let updatedValue = e.value === 'unselected' ? null : e.value;
                        let updatedDs = {
                          ...dataSpec,
                          [e.input]: updatedValue
                        };
                        setDataSpec(updatedDs);
                        debouncedUpdateDataSpec(updatedDs, e.input, updatedValue);
                      }
                    }}
                    // className={"space-y-6"}
                    flex={true}
                    data={{
                      data_list_source_id: {
                        label: 'Data List Provided By',
                        type: 'select',
                        options: (!dataListSources.loading && dataListSources.data) ? dataListSources.data.map(x => {return {value: x.id, label: x.name}}) : [],
                        required: false,
                        readonly: isFrozen !== IS_FROZEN_STATE.NOT_FROZEN,
                        value: dataSpec.data_list_source_id || undefined,
                        default: dataSpec.data_list_source_id,
                        errorMessage: 'Please select an organization',
                        isNullable: true,
                      },
                    }}>
                </Form>
                {dataSpec.data_list_source_id &&
                    <div className={"mx-2"}>
                      <h4 className={"text-lg font-medium leading-6 text-gray-900 mt-6 mb-2"}>Configure Data List Columns</h4>
                      {dataListColumns && dataListColumns.length > 0 ?
                          <DataListColumns className={"ml-3"}
                                           records={dataListColumns}
                                           deleteColumn={(id) => deleteListColumn(id)}
                          /> : <p className={"ml-3"}>No Columns Configured.</p>}

                      {columnErrors && columnErrors.length > 0 && <ErrorMessage errors={columnErrors} dismissOnClick={() => setColumnErrors(null)} className={"mt-4"} />}
                      {isFrozen === IS_FROZEN_STATE.NOT_FROZEN &&
                          <div className={"flex mt-4 ml-3"}>
                            <TextInput placeholder={"Column Name"} value={columnNameInput} onChange={(name, value) => setColumnNameInput(value)} outerClassName={"mr-2"} />
                            <TextInput placeholder={"Sample Value"} value={columnSampleValueInput} onChange={(name, value) => setColumnSampleValueInput(value)} outerClassName={"mr-2"} />
                            <TextInput placeholder={"Description"} value={descriptionInput} onChange={(name, value) => setDescriptionInput(value)} outerClassName={"mr-2"} />
                            <button
                                type="button"
                                onClick={async () => {
                                  console.log(columnNameInput, columnSampleValueInput, descriptionInput);
                                  const e = await addListColumn(columnNameInput, columnSampleValueInput, descriptionInput);
                                  if (!e) {
                                    setColumnErrors(null);
                                    setColumnNameInput('');
                                    setColumnSampleValueInput('');
                                    setDescriptionInput('');
                                  }
                                  else {
                                    setColumnErrors([e]);
                                  }
                                }}
                                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange-200"
                            >
                              Add Data List Column
                            </button>
                          </div>
                      }
                    </div>
                }
              </div>

              <div className={`p-4 my-4 shadow sm:rounded-lg ${isFrozen === IS_FROZEN_STATE.LOADING ? "bg-gray-50" : "bg-white"}`}>
                <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Device Configuration</h3>
                <p className="ml-2 mb-4 text-sm text-gray-400">
                  For orders with printing or encoding needs, configure printing and encoding records in this section
                </p>
                { tabs && tabs.find((tab) => tab.id === activeTab) &&
                    <>
                      <Tabs className={`${tabs.length + preTabButtons.length + postTabButtons.length  > 5 ? '' : tabs.length + preTabButtons.length + postTabButtons.length  === 5 ? 'w-5/6' : tabs.length + preTabButtons.length + postTabButtons.length  === 4 ? 'w-3/5' : tabs.length + preTabButtons.length + postTabButtons.length  === 3 ? 'w-1/2' : tabs.length + preTabButtons.length + postTabButtons.length === 2 ? 'w-2/6' :'w-1/4'}`}
                            activeTab={activeTab}
                            tabs={tabs}
                            preButtons={isFrozen !== IS_FROZEN_STATE.NOT_FROZEN ? [] : preTabButtons}
                            postButtons={isFrozen !== IS_FROZEN_STATE.NOT_FROZEN ? [] : postTabButtons}
                            handleTabClick={handleTabClick} />
                      {
                        tabs.find((tab) => tab.id === activeTab).name === NO_DATA_TAB.name ? <h3 className={"p-4 mt-2"}>If needed, add printing or chip configurations.</h3> :
                            tabs.find((tab) => tab.id === activeTab).name === PRINTING_TAB.name && printConfig ?
                                <PrintConfig
                                    printConfig={printConfig}
                                    dataSources={dataListColumns}
                                    enableAutoSave={true}
                                    isFrozen={isFrozen !== IS_FROZEN_STATE.NOT_FROZEN}
                                    isLoading={isFrozen === IS_FROZEN_STATE.LOADING}
                                    setPrintConfig={(pConfig, isDelete) => {
                                      if (isDelete) {
                                        setActiveTab(1);
                                        setPrintConfig(null);
                                      } else {
                                        setPrintConfig(pConfig);
                                      }
                                    }} /> :
                                <ChipConfig
                                    chipConfig={chipConfigs[activeTab-(printConfig?1:0)]}
                                    dataSources={dataListColumns}
                                    enableAutoSave={true}
                                    isFrozen={isFrozen !== IS_FROZEN_STATE.NOT_FROZEN}
                                    isLoading={isFrozen === IS_FROZEN_STATE.LOADING}
                                    setChipConfig={(chConfig, isDelete) => {
                                      if (isDelete) {
                                        setActiveTab(1);
                                        setChipConfigs((prev) => prev.filter((cc) => cc.id !== chConfig.id));
                                      } else {
                                        setChipConfigs((prev) => [...prev.map((cc) => cc.id === chConfig.id ? chConfig : cc)]);
                                      }
                                    }} />
                      }
                    </>
                }
              </div>

              <div className={"p-4 bg-white my-4 shadow sm:rounded-lg"}>
                <button
                    type="button"
                    className={classNames("ml-6 inline-flex align-top items-center rounded-md text-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200",
                        (!linkedOrdersFromServer.data || linkedOrdersFromServer.data.length > 0) ? "cursor-not-allowed text-gray-500": "text-red-600 hover:text-red-500",
                    )}
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                    disabled={!linkedOrdersFromServer.data || linkedOrdersFromServer.data.length > 0}
                >
                  <Icon image={'trash'} size={18} className={Style.labelIcon}/>
                  Delete Data Specification
                </button>
                {(!linkedOrdersFromServer.data || linkedOrdersFromServer.data.length > 0) &&
                    <div className={"text-gray-400"}>(Delete is disabled when the data specification is linked to orders)</div>
                }
              </div>
              <DeleteModal open={showDeleteModal}
                           setOpen={setShowDeleteModal}
                           onDelete={async () => {
                             return await deleteDataSpec(dataSpecId);
                           }}
              />
              <OverrideFrozenModal open={showFrozenOverrideModal}
                                   setOpen={setShowFrozenOverrideModal}
                                   overrideFrozenState={(b) => {
                                     if (b) {
                                       setHasOverrideFrozen(true);
                                       setIsFrozen(IS_FROZEN_STATE.NOT_FROZEN);
                                       setShowFrozenOverrideModal(false);
                                     }
                                   }}
              />
              <ChipModal open={openChipModal}
                         setOpen={setOpenChipModal}
                         context={modalContext}
                         addChip={(newChip) => {
                           setChipConfigs((prev) => [...prev, newChip]);
                           console.log('add chip, setActiveTab',chipConfigs.length, '+',printConfig?1:0, '=',chipConfigs.length+(printConfig?1:0));
                           setActiveTab(chipConfigs.length+(printConfig?1:0));
                         }}
                         enableAutoSave={true}
                         data_spec_id={dataSpecId}
              />
              <PreviewPdf open={openPdfPreview}
                          setOpen={setOpenPdfPreview}
                          dataSpec={dataSpec}
                          customer={customers.data.find(c => c.id === dataSpec.customer_id)}
                          assignedTo={users.data.find(u => u.id === dataSpec.assigned_to_id)}
                          printConfig={printConfig}
                          chipConfigs={chipConfigs}
                          dataListColumns={dataListColumns}
                          lastSaveTime={lastSaveTime}
              />
            </>
        }
      </Fragment>
  )
}


function OverrideFrozenModal (props) {

  const cancelButtonRef = useRef(null);

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="">
                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                      Override Data Specification Frozen State
                    </Dialog.Title>
                    <div className="mt-4">
                      <p className="text-sm mb-2">This data spec has orders linked to it that have already been put into production, editing should be limited to fixing issues.</p>
                      <p className="text-sm mb-4">If the edits are for a new order, then you should create a new data spec based on this one instead.</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={() => props.overrideFrozenState(true)}
                  >
                    Override Frozen State
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
function DeleteModal (props) {

  const cancelButtonRef = useRef(null);
  const [deleteErrors, setDeleteErrors] = useState(undefined);

  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                    Are you sure you want to proceed with deletion?
                  </Dialog.Title>
                </div>
                <div className="mt-12">
                  <p className="text-base mb-0">Delete Data Specification</p>
                  <p className="text-sm text-red-600 mb-4"><strong className={"text-red-600"}>WARNING</strong> - This action cannot be undone.</p>
                  {(deleteErrors && deleteErrors.length > 0) && <div className={"flex mt-4 text-red-500 text-xs"}>
                    {
                      deleteErrors.map((error, index) => {
                        return (
                            <div key={'deleteError_' + index}>
                              {error.message ? error.message : error}
                              <br />
                              {error.additionalErrorText}
                            </div>
                        );
                      })
                    }
                  </div>}
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={async () => {
                        setDeleteErrors(undefined);
                        const errors = await props.onDelete();
                        if (errors) {
                          setDeleteErrors(errors);
                        }
                      }}
                  >
                    Delete Data Spec
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                      }}
                      ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
