import React, {Fragment, useContext, useRef, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {AuthContext, ErrorMessage, Form, useAPI} from 'components/lib';
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function CreateCaidScopeModal(props) {
  const context = useContext(AuthContext);

  const caidTypes = useAPI('/api/caidTypes/');

  const cancelButtonRef = useRef(null);
  const [errors, setErrors] = useState([]);
  const [SequenceType, setSequenceType] = useState(false);

  let [caidScope, setCaidScope] = useState({
    customer_id: props.customerId,
    caidFormat: {},
  });

  async function createCaidScope(newCaidScope) {
    // Validate new record
    if (!newCaidScope) {
      return ['Data was empty'];
    }
    const errors = [];
    if (!newCaidScope.customer_id) {
      errors.push('Organization was missing');
    }
    if (errors.length > 0) {
      return errors;
    }

    // Need to split out caid_format data first
    const finalCaidScope = {
      name: newCaidScope.name,
      description: newCaidScope.description || undefined,
      customer_id: newCaidScope.customer_id,
      created_by_id: context.user?.user_id,
      caidFormat: {
        caid_type_id: newCaidScope.caid_type_id,
        min_length: newCaidScope.min_length || undefined,
        max_length: newCaidScope.max_length || undefined,
        allow_leading_zeros: newCaidScope.allow_leading_zeros || undefined,
        starting_value: newCaidScope.starting_value || undefined,
        prefix: newCaidScope.prefix || undefined,
        suffix: newCaidScope.suffix || undefined,
        numberOf_digits: newCaidScope.numberOf_digits || undefined,
        starting_number: newCaidScope.starting_number || undefined,
        nonUniqueData: newCaidScope.nonUniqueData || undefined
      }
    }

    // send create request to server
    const response = await axios({

      method: 'post', // TODO: complete this
      url: '/api/caidScope/',
      data: {
        caidScope: {
          ...finalCaidScope,
        }
      },

    });

    if (response.status === 200) {
      if (props.afterCreate) {
        props.afterCreate(response.data.data);
      }
      return;
    }
    else if (response.status !== 200) {
      // return error if not '200 OK'
      return [{
        errorStatus: response.status,
        errorStatusText: response. statusText,
        message: 'Error when creating CAID Scope.'
      }]
    }
  }

  let data_inputs={
    caid_type_id: {
      label: 'CAID Type',
      type: 'select',
      options: (!caidTypes.loading && caidTypes.data) ? caidTypes.data.map(x => {return {value: x.id, label: x.name}}) : [],
      required: true,
      value: caidScope.caid_type_id,
      errorMessage: 'Please select a CAID Type',
    },
    name: {
      label: 'Name',
      type: 'text',
      required: true,
      value: caidScope.name,
      errorMessage: 'Please enter a name',
    },
    description: {
      label: 'Description',
      type: 'text',
      required: false,
      value: caidScope.description,
      errorMessage: 'Please enter a description',
    },
    numberOf_digits: {
      label: 'Number of digits',
      type: 'text',
      required: true,
      isHidden: !SequenceType,
      value: caidScope.numberOf_digits,
      errorMessage: 'Please enter a minimum length',
    },
    starting_number: {
      label: 'Starting Number',
      type: 'text',
      required: true,
      isHidden: !SequenceType,
      value: caidScope.starting_number,
      errorMessage: 'Please enter a minimum length',
    },
    min_length: {
      label: 'Min. Length',
      type: 'text',
      required: false,
      value: caidScope.min_length,
      errorMessage: 'Please enter a minimum length',
    },
    max_length: {
      label: 'Max. Length',
      type: 'text',
      required: false,
      value: caidScope.max_length,
      errorMessage: 'Please enter a maximum length',
    },
    allow_leading_zeros: {
      label: 'Allow Leading Zeros',
      type: 'singleCheckbox',
      required: false,
      isHidden: SequenceType,
      value: caidScope.allow_leading_zeros,
      errorMessage: 'Please select an options',
    },
    prefix: {
      label: 'Prefix',
      type: 'text',
      required: false,
      value: caidScope.prefix,
      errorMessage: 'Please enter a prefix',
    },
    suffix: {
      label: 'Suffix',
      type: 'text',
      required: false,
      value: caidScope.suffix,
      errorMessage: 'Please enter a suffix',
    },
    nonUniqueData: {
      label: 'Is non-unique data?',
      type: 'singleCheckbox',
      required: false,
      value: caidScope.nonUniqueData,
      errorMessage: 'Please select an options',
    },
  }




  return (
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={props.setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all mt-12 sm:mt-16 sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="">
                    <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                      {props.allowExistingLink ? 'Link or ' : ''}Create CAID
                    </Dialog.Title>
                    {errors && errors.length > 0 && <ErrorMessage errors={errors} className={"mt-4"} />}

                    <div className="mt-2">
                      <Form
                          updateOnChange={true}
                          onChange={(e) => {
                            console.log(e.input + e.value);
                            if(e.input=="caid_type_id"){
                              e.value=="2" ? setSequenceType(true) : setSequenceType(false);
                            }

                            setCaidScope({
                              ...caidScope,
                              [e.input]: e.value
                            })
                          }}
                          className={"p-1 bg-white space-y-4 sm:rounded-lg"}
                          flex={true}
                          data={data_inputs}>
                      </Form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:col-start-2 sm:text-sm"
                      onClick={async () => {
                        const e = await createCaidScope(caidScope);
                        if (!e) {
                          props.setOpen(false);
                          setErrors([]);
                        } else {
                          setErrors(e);
                        }
                      }}
                  >
                    {'Create CAID'}
                  </button>
                  <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-200 sm:mt-0 sm:col-start-1 sm:text-sm"
                      onClick={() => {
                        props.setOpen(false);
                        setErrors([]);
                      }}
                      ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
