
import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {AppLayoutContext, AuthContext, Button, Form, Icon, Loader, useAPI} from 'components/lib';
import {isChipUidReferenced} from "../dataspec/shared/util";
import {PreviewPdf} from "../dataspec/export/PreviewPdf";
import Style from "../../components/hero/hero.tailwind";
import axios from "axios";
import fileDownload from "js-file-download";
import Papa from "papaparse";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function FactoryExport(props){
  // Custom hooks
  const { orderId, orderItemId } = useParams();
  let query = useQuery();
  let navigate = useNavigate();

  // get User context
  const context = useContext(AuthContext);

  // Data from server
  // const customers = useAPI('/api/customer/');
  const orderFromApi = useAPI('/api/orders/' + orderId);
  const [orderItemFromApi, setOrderItemFromApi] = useAPI('/api/orderItem/' + orderItemId + '/generations', 'get', true);
  const dataSpecFromApi = useAPI('/api/dataSpec/' + query.get('dataSpecId'));
  const dataListColumnsFromServer = useAPI(`/api/dataSpec/${query.get('dataSpecId')}/dataListColumns`);
  const customers = useAPI('/api/customer/');
  const users = useAPI('/api/user/');

  // State management
  const [uploadedDataFile, setUploadedDataFile] = useState(undefined);
  const [userUploadHeaders, setUserUploadHeaders] = useState(undefined);
  const [userUploadRowcount, setUserUploadRowcount] = useState(undefined);
  // columnMappings format: {<dataListColId>:<caidScopeId/userUploadCol>}
  const [columnMappings, setColumnMappings] = useState({});
  const [includeDataSourceAsColumns, setIncludeDataSourceAsColumns] = useState(false);
  const [returnChipUid, setReturnChipUid] = useState(true);
  const [returnUnrealized, setReturnUnrealized] = useState(true);
  const [separatorOption, setseparatorOption] = useState(false);
  const [delimiter, setdelimiter] = useState('Comma');
  const [otherDelimiter, setOtherDelimiter] = useState('');
  const [quote, setQuote] = useState('');
  const [error_ss, setError_ss] = useState(false);


  const [importSeparatorOption, setimportSeparatorOption] = useState(false);
  const [importDelimiter, setimportDelimiter] = useState('');
  const [importotherdelimiter, setimportotherdelimiter] = useState('');
  const [importQuote, setimportQuote] = useState('');
  const [importError_ss, setimportError_ss] = useState(false);


  const [exportFileName, setExportFileName] = useState('');
  const [exportPendingMessage, setExportPendingMessage] = useState(undefined);
  const [openPdfPreview, setOpenPdfPreview] = useState(false);
  // console.log(columnMappings);


  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);

  // Auto-generate default export file name
  useEffect(() => {
    if (orderFromApi.data && orderItemFromApi.data && exportFileName === '') {
      console.log(orderFromApi.data, orderItemFromApi.data)
      setExportFileName(`${orderFromApi.data.vtiger_salesorder_no}_LineItem${orderItemFromApi.data.number}`)
    }
  }, [orderFromApi.data, orderItemFromApi.data]);

  useEffect(() => {
    // If data has been received from server AND the columnMappings haven't been updated yet, then try auto-mapping
    if (dataListColumnsFromServer.data && orderItemFromApi.data && Object.keys(columnMappings).length === 0) {
      orderItemFromApi.data.idgencounts.forEach(x => {
        if (!x.count) {
          // fields are null if no idgen
          return;
        }
        const foundMatch = dataListColumnsFromServer.data.find(dlc => dlc.name.toLowerCase() === x.caid_scope_name.toLowerCase());
        if (foundMatch) {
          setColumnMappings(prev => {
            return {
              ...prev,
              [foundMatch.id]: x.caid_scope_id
            }
          });
        }
      })
    }
  }, [dataListColumnsFromServer.data, orderItemFromApi.data, columnMappings]);

  // If user upload path, then load columns to map (variable data side)
  useEffect( () => {
    async function asyncFunction() {
      if (orderItemFromApi.data && orderFromApi.data && orderItemFromApi.data.data_file_upload_file_id) {
        // If the uploaded file exists, then just use that
        let csv;

        if (uploadedDataFile) {
          // TODO: this fails because it expects blob?
          //  TypeError: Failed to execute 'readAsText' on 'FileReader': parameter 1 is not of type 'Blob'.
          csv = Papa.parse(uploadedDataFile[0], {header: true});
          const parsedData = csv?.data;
          const headers = Object.keys(parsedData[0]);

          let lastRowIsEmpty = true;
          let checkIndex = headers.length - 1;
          while (lastRowIsEmpty && checkIndex > 0) {
            lastRowIsEmpty = (headers[checkIndex] === "");
            if (lastRowIsEmpty) {
              checkIndex--;
            }
          }

          // Remove all empty rows
          headers.splice(checkIndex + 1);

          setUserUploadHeaders(headers);
          setUserUploadRowcount(parsedData.length);

        }
        // If not, then download file from server and use that
        else {
          const res = await axios({
            method: 'get',
            url: `/api/dataListUpload/${orderFromApi.data.customer_id}/upload/${orderItemId}`,
          });

          Papa.parse(res.data.fileData, {
            header: true,
            complete: (csvData) => {
              const parsedData = csvData?.data;
              const headers = Object.keys(parsedData[0]);

              let lastRowIsEmpty = true;
              let checkIndex = headers.length - 1;
              while (lastRowIsEmpty && checkIndex > 0) {
                lastRowIsEmpty = (headers[checkIndex] === "");
                if (lastRowIsEmpty) {
                  checkIndex--;
                }
              }

              // Remove all empty rows
              headers.splice(checkIndex + 1);

              setUserUploadHeaders(headers);
              setUserUploadRowcount(parsedData.length);
            }
          });

        }

      }
    }
    asyncFunction();
  }, [uploadedDataFile, orderItemFromApi.data, orderFromApi.data]);

  // API Calls triggered by user
  async function factoryExport(orderItemId, exportFileName, columnMappings) {
    let url; let separator_ss;
    let otherSeperator=otherDelimiter;

    // send download request to server
    if(otherDelimiter===''){
      separator_ss=delimiter;
    }else{
      separator_ss=otherDelimiter;
    }
    // console.log("OtherDel:"+encodeURI(quote));

    url=`/api/orderItem/${orderItemId}/factoryExport`;
    const response = await axios({

      method: 'post',
      url: url,
      responseType: 'blob',
      data: {
        exportFileName,
        columnMappings,
        includeDataSourceAsColumns,
        separator_ss,
        quote,
        otherSeperator
      },

    });
    fileDownload(response.data, `${exportFileName}.csv`);
  }


  return (
      <Fragment>
        { !customers.data || !users.data || !orderFromApi.data || !dataSpecFromApi.data || !dataListColumnsFromServer.data || !orderItemFromApi.data ?
            <Loader /> :
            <>
              <div className={"flex mb-4 -mt-2"}>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/orders`}>
                  Orders
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <a className={"relative text-sm hover:underline text-orange-500"}
                   href={`/order/${orderId}`}>
                  {orderFromApi.data.vtiger_salesorder_no}
                </a>
                <span className={"mx-2 text-sm text-gray-400"}> / </span>
                <p className={"relative text-sm text-gray-500"}>
                  Factory Export
                </p>
              </div>
              <div className={"shadow bg-gray-50 rounded-lg p-3"}>
                <div className={"grid-cols-2 grid"}>
                  <div>
                    <div className={"flex flex-col"}>
                      <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Data List Columns</h3>
                      <div className={"ml-3 text-sm"}>
                        <span>via</span>
                        <a className={"relative hover:underline text-orange-500 ml-1"}
                           href={`/dataspec/${query.get('dataSpecId')}`}>
                          {dataSpecFromApi.data.number}
                        </a>
                      </div>
                    </div>
                    {dataListColumnsFromServer.data.map((dlc,index) => {
                      return (
                          <div key={dlc.name} className={`ml-2 p-3 rounded-l flex items-center ${index%2===0 ? 'bg-gray-50':'bg-gray-100'}`}>
                            <span className={"text-2xl font-semibold text-gray-900"}>{index+1}</span>
                            <div className={"flex flex-col ml-4"}>
                              <span className={"font-semibold"}>{dlc.name}</span>
                              <span className={"text-sm text-gray-500 -mt-1"}>{dlc.sample_value}</span>
                            </div>
                          </div>
                      );
                    })
                    }
                    {isChipUidReferenced(dataSpecFromApi.data.printConfig, dataSpecFromApi.data.transponderConfigs) &&
                        <div className={`ml-2 p-3 rounded-l h-[64px] flex items-center ${dataListColumnsFromServer.data.length%2===0 ? 'bg-gray-50':'bg-gray-100'}`}>
                          <span className={"text-2xl font-semibold text-red-500"}>{dataListColumnsFromServer.data.length + 1}</span>
                          <div className={"flex flex-col ml-4 text-red-500"}>
                            <span className={"font-semibold"}>Chip UID</span>
                          </div>
                        </div>
                    }
                  </div>
                  <div>
                    <div className={"flex flex-col"}>
                      <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Variable Data</h3>
                      {query.get('generationConfigId') ? (<div className={"ml-3 text-sm"}>
                        <span>via</span>
                        {query.get('generationConfigId') && <div className={"inline-flex"}>
                          <span className={"ml-1"}>generated data</span>
                          <div className={"flex ml-1 hidden lg:flex"}>
                            (
                            {orderItemFromApi.data.idgencounts.map((idgc, index) => {
                              return (

                                  <div className={`flex ${index !== orderItemFromApi.data.idgencounts.length - 1 ? 'mr-1':'mr-0'}`} key={`${idgc.caid_scope_id}_${idgc.caid_batch_id}`}>
                                    <a
                                        href={`/customer/${orderFromApi.data.customer_id}/caid/${idgc.caid_scope_id}?batchId=${idgc.caid_batch_id}`}
                                        className="hover:underline text-sm text-orange-500">
                                      {idgc.caid_scope_name}
                                    </a>
                                    {index !== orderItemFromApi.data.idgencounts.length - 1 ? ', ' : ''}
                                  </div>);

                            })})
                          </div>
                        </div>}
                      </div>) : (
                          <div className={"ml-3 text-sm"}>
                            <span>via</span>
                            <div className={"inline-flex items-center"}>
                              <span className={"ml-1"}>manual upload</span>
                              {orderItemFromApi.data.data_file_upload_file_id && <><div className={"flex ml-1 hidden lg:flex"}>

                                <div
                                    className={`flex`}>
                                  (
                                  <a
                                      href={orderItemFromApi.data.data_file_upload_weblink}
                                      target="_blank"
                                      className="hover:underline text-sm text-orange-500">
                                    Google Drive link
                                  </a>
                                  )
                                </div>
                              </div>
                                {userUploadRowcount && <span className={"text-gray-400 mx-1"}>
                          {userUploadRowcount.toLocaleString()} rows
                        </span>}
                                <span className={"cursor-pointer ml-2"} onClick={async () => {
                                  // delete generation batches and generation config when trigger delete
                                  const response = await axios({

                                    method: 'delete',
                                    url: `/api/dataListUpload/${orderFromApi.data.customer_id}/upload/${orderItemId}`,
                                    data: {},

                                  });
                                  window.location.reload();
                                }
                                }>
                          <Icon
                              className='icon'
                              image={'trash'}
                              size={16}
                              color={'red'}
                          />
                        </span></>}
                            </div>
                          </div>
                      )}
                    </div>
                    {(!query.get('generationConfigId') && !orderItemFromApi.data.data_file_upload_file_id) ? (
                        <div className={"mt-8 mx-8"}>
                          <div className={"SeparatorOption mb-4"} >
                            <Form
                                className={"p-1 bg-white space-y-2 sm:rounded-lg"}
                                flex={true}
                                url={`/api/dataListUpload/${orderFromApi.data.customer_id}/upload/${orderItemId}`}
                                method='POST'
                                buttonTextSm='Upload data file'
                                updateOnChange={true}
                                onChange={(e) => {
                                  if(e.input==='dataFile'){
                                    setUploadedDataFile(e.value);
                                  }else{
                                    if(e.input==='importSeparation'){
                                      setimportSeparatorOption(e.value);
                                    }
                                    else{
                                      if(e.input==="quate"){
                                        if(e.value===''){
                                          setimportQuote(null);
                                        }else{
                                          if(e.value==="(" || e.value===")"){
                                            setimportError_ss(true);
                                          }else{
                                            setimportError_ss(false);
                                          }
                                          setimportQuote(e.value);
                                        }
                                      }else{
                                        if(e.input==='otherSeperator'){
                                          setimportotherdelimiter(e.value);
                                        }else{
                                          setimportDelimiter(e.value);
                                          setimportotherdelimiter('');
                                        }

                                      }
                                    }
                                  }

                                }}
                                callback={(s) => {
                                  console.log("Callback Action: "+JSON.stringify(s));
                                  window.location.reload();
                                  // TODO: uncomment to try making this work
                                  // console.log(s);
                                  // setOrderItemFromApi(prev => {
                                  //   return {
                                  //     ...prev,
                                  //     data: {
                                  //       ...prev.data,
                                  //       data_file_upload_file_id: s.data.data_file_upload_file_id,
                                  //       data_file_upload_weblink: s.data.data_file_upload_weblink,
                                  //     }
                                  //   };
                                  // });
                                }}
                                data={{
                                  dataFile: {
                                    label: '',
                                    type: 'file',
                                    required: true,
                                    value: uploadedDataFile,
                                    errorMessage: 'Please upload a file',
                                  },
                                  importSeparation: {
                                    label: 'Separator Options',
                                    type: 'singleCheckbox',
                                    value: importSeparatorOption,
                                    required: false,
                                    errorMessage: 'Please select an option',
                                  },
                                  separator_ss: {
                                    type: 'radio',
                                    options: ["Tab", "Comma", "Semicolon", "Space", "Other"],
                                    required: false,
                                    errorMessage: 'Please select an option',
                                    value:importDelimiter,
                                    label: 'Separated by',
                                    isHidden: !importSeparatorOption,
                                  },
                                  otherSeperator: {
                                    label: '',
                                    type: 'text',
                                    value: importotherdelimiter,
                                    isHidden:importDelimiter!="Other",
                                    required: false,
                                    errorMessage: 'Separated by',
                                  },
                                  quate: {
                                    label: 'String Delimiter',
                                    type: 'text',
                                    value: importQuote,
                                    required: false,
                                    isHidden: !importSeparatorOption,
                                    errorMessage: 'Please enter string delimiter',
                                  },
                                  userId: {
                                    label: 'userID',
                                    type: 'hidden',
                                    required: true,
                                    value: context.user?.user_id,
                                  },
                                }}>
                            </Form>
                            <span className="error_ss" style={{display: importError_ss === true ? 'block' : 'none'}}>Unsupported String Delimeter</span>
                          </div>
                        </div>
                    ) : (!query.get('generationConfigId') && orderItemFromApi.data.data_file_upload_file_id) ?
                        (<>
                          {dataListColumnsFromServer.data.map((dlc, index) => {
                            return (
                                <div key={dlc.name} className={`px-3 py-[3px] rounded-r flex items-center ${index%2===0 ? 'bg-gray-50':'bg-gray-100'}`}>
                                  <Form
                                      updateOnChange={true}
                                      onChange={(e) => {
                                        setColumnMappings(prev => {
                                          return {
                                            ...prev,
                                            [dataListColumnsFromServer.data[index].id]: e.value
                                          }
                                        });
                                      }}
                                      className={`${index%2===0 ? 'bg-gray-50':'bg-gray-100'} p-2 rounded-sm w-72 inline-block`}
                                      flex={false}
                                      data={{
                                        caidScopes: {
                                          label: ' ',
                                          type: 'customSelect',
                                          options: userUploadHeaders ? userUploadHeaders.map(x => {return {value: x, label: x, secondaryText: userUploadRowcount ? `${userUploadRowcount.toLocaleString()} items` : '', orig: x}}) : [],
                                          value: columnMappings[dataListColumnsFromServer.data[index].id],
                                          required: false,
                                          // default:  dataRecord.data_sources ? dataRecord.data_sources : [],
                                          errorMessage: 'Please select an option',
                                        },
                                      }}>
                                  </Form>
                                </div>
                            );
                          })
                          }
                          {isChipUidReferenced(dataSpecFromApi.data.printConfig, dataSpecFromApi.data.transponderConfigs) &&
                              <div className={`px-3 py-[3px] rounded-r flex h-[64px] items-center ${dataListColumnsFromServer.data.length%2===0 ? 'bg-gray-50':'bg-gray-100'}`}>
                                <div className={"flex flex-col ml-4 text-red-500"}>
                                  <span className={"font-semibold"}>(provided by factory)</span>
                                </div>
                              </div>}
                        </>) : (<>
                          {dataListColumnsFromServer.data.map((dlc, index) => {
                            return (
                                <div key={dlc.name} className={`px-3 py-[3px] rounded-r flex items-center ${index%2===0 ? 'bg-gray-50':'bg-gray-100'}`}>
                                  <Form
                                      updateOnChange={true}
                                      onChange={(e) => {
                                        setColumnMappings(prev => {
                                          return {
                                            ...prev,
                                            [dataListColumnsFromServer.data[index].id]: e.value
                                          }
                                        });
                                      }}
                                      className={`${index%2===0 ? 'bg-gray-50':'bg-gray-100'} p-2 rounded-sm w-72 inline-block`}
                                      flex={false}
                                      data={{
                                        caidScopes: {
                                          label: ' ',
                                          type: 'customSelect',
                                          options: orderItemFromApi.data ? orderItemFromApi.data.idgencounts.map(x => {return {value: x.caid_scope_id, label: `${x.caid_scope_name}`, secondaryText: `${x.count/orderItemFromApi.data.idgencounts.length} items`, orig: x}}) : [],
                                          value: columnMappings[dataListColumnsFromServer.data[index].id],
                                          required: false,
                                          // default:  dataRecord.data_sources ? dataRecord.data_sources : [],
                                          errorMessage: 'Please select an option',
                                        },
                                      }}>
                                  </Form>
                                </div>
                            );
                          })
                          }
                          {isChipUidReferenced(dataSpecFromApi.data.printConfig, dataSpecFromApi.data.transponderConfigs) &&
                              <div className={`px-3 py-[3px] rounded-r flex h-[64px] items-center ${dataListColumnsFromServer.data.length%2===0 ? 'bg-gray-50':'bg-gray-100'}`}>
                                <div className={"flex flex-col ml-4 text-red-500"}>
                                  <span className={"font-semibold"}>(provided by factory)</span>
                                </div>
                              </div>}
                        </>)}
                  </div>
                </div>
                <div className={"mt-2"}>
                  <Form
                      updateOnChange={true}
                      onChange={(e) => {
                        setIncludeDataSourceAsColumns(e.value);
                      }}
                      className={`p-2 rounded-sm w-72 inline-block`}
                      flex={false}
                      data={{
                        caidScopes: {
                          label: 'Include Data Sources in CSV Export',
                          type: 'singleCheckbox',
                          value: includeDataSourceAsColumns,
                          required: false,
                          errorMessage: 'Please select an option',
                        },
                      }}>
                  </Form>
                </div>
                <div className={"mt-4"}>
                  <div className={"flex flex-col"}>
                    <h3 className={"text-xl font-semibold ml-2 leading-6 text-gray-900"}>Factory Instructions</h3>
                    <div className="flex mb-2 ml-2 text-gray-400 items-center">
                      <Icon
                          image='info'
                          size={ 14 }
                      />
                      <p className="ml-1 text-sm text-gray-400">
                        Specify what data and format is needed back from the factory
                      </p>
                    </div>
                  </div>
                  <div>
                    <Form
                        updateOnChange={true}
                        onChange={(e) => {
                          setReturnChipUid(e.value);
                        }}
                        className={`p-2 rounded-sm w-72 inline-block`}
                        flex={false}
                        data={{
                          caidScopes: {
                            label: 'Return Chip UID',
                            type: 'singleCheckbox',
                            value: returnChipUid,
                            required: false,
                            errorMessage: 'Please select an option',
                          },
                        }}>
                    </Form>
                    <Form
                        updateOnChange={true}
                        onChange={(e) => {
                          setReturnUnrealized(e.value);
                        }}
                        className={`p-2 rounded-sm w-72 inline-block`}
                        flex={false}
                        data={{
                          caidScopes: {
                            label: 'Return all rows (including unrealized)',
                            type: 'singleCheckbox',
                            value: returnUnrealized,
                            required: false,
                            errorMessage: 'Please select an option',
                          },
                        }}>
                    </Form>
                  </div>
                  <div className={""}>
                    <Form
                        updateOnChange={true}
                        onChange={(e) => {
                          setseparatorOption(e.value);
                        }}
                        className={`p-2 rounded-sm w-72 inline-block`}
                        flex={false}
                        data={{
                          caidScopes: {
                            label: 'Separator Options',
                            type: 'singleCheckbox',
                            value: separatorOption,
                            required: false,
                            errorMessage: 'Please select an option',
                          },

                        }}>
                    </Form>
                    {separatorOption &&
                        <div className={"SeparatorOption mb-4"} >
                          <Form
                              updateOnChange={true}
                              onChange={(e) => {

                                if(e.input==="quote"){
                                  if(e.value==="(" || e.value===")"){
                                    setError_ss(true);
                                  }else{
                                    setError_ss(false);
                                  }
                                  setQuote(e.value);

                                }else{
                                  if(e.input==='otherSeperator'){
                                    setOtherDelimiter(e.value);
                                  }else{
                                    setdelimiter(e.value);
                                    setOtherDelimiter('');
                                  }
                                }

                              }}
                              className={`p-2 w-72 inline-block`}
                              flex={true}
                              data={{
                                separator_ss: {
                                  type: 'radio',
                                  options: ["Tab", "Comma", "Semicolon", "Space", "Other"],
                                  required: false,
                                  errorMessage: 'Please select an option',
                                  value:delimiter,
                                  label: 'Separated by',
                                },
                                otherSeperator: {
                                  label: '',
                                  type: 'text',
                                  value: otherDelimiter,
                                  isHidden:delimiter!="Other",
                                  required: false,
                                  errorMessage: 'Separated by',
                                },
                                quote: {
                                  label: 'String Delimiter',
                                  type: 'text',
                                  value: quote,
                                  required: false,
                                  errorMessage: 'Please enter string delimiter',
                                },
                              }}
                          >
                          </Form>
                          <span className="error_ss" style={{display: error_ss == true ? 'block' : 'none'}}>Unsupported String Delimeter</span>
                        </div>
                    }

                  </div>
                  <div className={"flex"}>
                    <Form
                        updateOnChange={true}
                        onChange={(e) => {
                          setExportFileName(e.value);
                        }}
                        className={`p-2 rounded-sm w-72 inline-block`}
                        flex={false}
                        data={{
                          caidScopes: {
                            label: 'Export Filename',
                            type: 'text',
                            value: exportFileName,
                            required: false,
                            errorMessage: 'Please enter a filename',
                          },
                        }}>
                    </Form>
                    <div className={"-ml-[160px] mt-2"}>
                  <span className={"ml-1 flex items-center text-gray-400"}>
                    <Icon
                        image='info'
                        size={ 14 }
                    />
                    <p className="ml-1 text-sm text-gray-400">
                      used for pdf & csv
                    </p>
                  </span>
                    </div>
                  </div>
                </div>

                <div className={"flex"}>
                  <Button
                      disabled={!(exportFileName && Object.keys(columnMappings).length === dataListColumnsFromServer.data.length)}
                      action={async () => {
                        await factoryExport(orderItemId, exportFileName, columnMappings);
                      }}
                      text={exportPendingMessage ? exportPendingMessage : 'Create Factory Export (CSV)'}
                      small
                      className={`mt-2 mr-4 border border-transparent
              ${(exportFileName && Object.keys(columnMappings).length === dataListColumnsFromServer.data.length) ?
                          "bg-orange-500 hover:bg-orange-700 w-96" :
                          "bg-gray-400 cursor-not-allowed w-96"}`}/>

                  <Button
                      action={async () => {
                        setOpenPdfPreview(true)
                      }}
                      text={'Export Factory PDF '}
                      small
                      className={`mt-2 mr-4 border border-transparent bg-orange-500 hover:bg-orange-700 w-96`}/>


                  <Button
                      action={async () => {
                        navigate(`/order/${orderId}`);
                      }}
                      text={'Return to Order'}
                      small
                      className={`mt-2 mr-2 w-72
                ${!exportPendingMessage ? 'border text-gray-700 bg-indigo-700 hover:bg-indigo-700' : 'border border-transparent bg-orange-500 hover:bg-orange-700'}`}/>
                </div>
              </div>
              <PreviewPdf open={openPdfPreview}
                          setOpen={setOpenPdfPreview}
                          dataSpec={dataSpecFromApi.data}
                          customer={customers.data.find(c => c.id === dataSpecFromApi.data.customer_id)}
                          assignedTo={users.data.find(u => u.id === dataSpecFromApi.data.assigned_to_id)}
                          printConfig={dataSpecFromApi.data.printConfig}
                          chipConfigs={dataSpecFromApi.data.transponderConfigs}
                          dataListColumns={dataListColumnsFromServer.data}
                          lastSaveTime={dataSpecFromApi.data.updated_at}
                          factoryInstructions={{
                            returnChipUid: returnChipUid,
                            returnUnrealized: returnUnrealized,
                          }}
              />
            </>
        }
      </Fragment>
  );
}
